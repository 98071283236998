import React, { Component } from "react";
import loadingGif from "./images/YouTube_loading_symbol_2_(stable).gif";
import stripeLogo2 from "./images/powered_by_stripe_dark.png";
import Subscription from "./subscriptionmodel.js";

class SubscriptionInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scoreboardOnline: props.scoreboardOnline,
      apiRepo: props.scoreboardOnline.apiRepo,
      subscriptionInfo: null,
      reload: true
    };
  }

  getSubscriptionInfo() {
    this.state.apiRepo.getSubscriptionInfo(
      this.subscriptionInfoReturnMethod,
      this.subscriptionInfoErrorMethod
    );
  }

  subscriptionInfoReturnMethod = resultsJson => {
    let subscriptionInfo = [];

    for (let i = 0; i < resultsJson.length; i++) {
      let newSubscription = new Subscription(resultsJson[i]);
      subscriptionInfo.push(newSubscription);
    }

    this.subscriptionData();

    this.setState({
      subscriptionInfo: subscriptionInfo,
      reload: false
    });
  };

  subscriptionInfoErrorMethod = () => {
    alert(
      "We're sorry, but we're unable to get your subscription information at this time. Please try again later."
    );
  };

  cancelSubscription = subscriptionId => {
    this.state.apiRepo.cancelSubscription(
      subscriptionId,
      this.cancelSubscriptionReturnMethod,
      this.cancelSubscriptionErorMethod
    );
  };

  cancelSubscriptionReturnMethod = resultsJson => {
    let subscriptionInfo = this.state.subscriptionInfo;

    for (let i = 0; i < subscriptionInfo.length; i++) {
      if (subscriptionInfo[i] === resultsJson.subscriptionId) {
        subscriptionInfo.active = "canceled";
        subscriptionInfo.nextRenewalDate = new Date();
        return;
      }
    }

    this.subscriptionData();

    this.setState({
      subscriptionInfo: subscriptionInfo,
      reload: true
    });
  };

  cancelSubscriptionErorMethod = () => {
    alert(
      "We're sorry, but we're unable to cancel your subscription information at this time. Please try again later."
    );
  };

  subscriptionData = () => {
    this.state.apiRepo.subscriptionData(
      this.subscriptionReturnMethod,
      this.subscriptionErrorMethod
    );
  };

  subscriptionReturnMethod = resultsJson => {
    let scoreboardOnline = this.state.scoreboardOnline;

    if (resultsJson.subscriptionEndDate !== null){
      scoreboardOnline.subscriptionStatus = (resultsJson.subscriptionEndDate ==="active");
    }

    if (resultsJson.freeGames !== null) {
      scoreboardOnline.freeGames = resultsJson.freeGames;
    }

    if (resultsJson.gameList !== null && resultsJson.gameList !== undefined) {
      for (let i = 0; i < resultsJson.gameList.length; i++) {
        scoreboardOnline.freeGameList.push(resultsJson.gameList[i]);
      }
    }
  };
  displaySubscription = item => {
    return (
      <table>
        <tbody>
          <tr>
            <td colSpan="2">
              <a
                href="https://stripe.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={stripeLogo2} alt="Stripe.com" />
              </a>
            </td>
          </tr>
          <tr>
            <td>Last billing status:</td>
            <td>{item.status}</td>
            <td colSpan="2">{item.failureMessage}</td>
          </tr>
          <tr>
            <td>Status:</td>
            <td
              className={
                item.active === "active" ? "shownNotification" : "shownError"
              }
            >
              {item.active}
            </td>
          </tr>
          <tr>
            <td>Amount:</td>
            <td>
              ${(item.amount / 100).toFixed(2)} {item.currency}
            </td>
          </tr>
          <tr>
            <td>Subscription:</td>
            <td>{item.subscriptionStart.toLocaleDateString()}</td>
            <td>To:</td>
            <td>{item.nextRenewalDate.toLocaleDateString()}</td>
          </tr>
          <tr>
            <td>Card info:</td>
            <td>{item.last4}</td>
            <td>{item.brand}</td>
            <td>
              {item.expirationMonth}/{item.expirationYear}
            </td>
          </tr>
          {item.refundedStatus === 1 && (
            <tr>
              <td>Was refunded:</td>
              <td>Yes</td>
            </tr>
          )}
          {item.active === "active" && (
            <tr>
              <td colSpan="2">
                <input
                  type="button"
                  onClick={() => this.cancelSubscription(item.subscriptionId)}
                  value="Cancel Subscription"
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  render() {
    let me = this;

    if (this.state.reload) {
      this.getSubscriptionInfo();
    }

    return (
      <div>
        <h2>Subscriptions:</h2>
        {this.state.subscriptionInfo === null ? (
          <img
            className="loading"
            src={loadingGif}
            alt="Loading you subscription information..."
          />
        ) : this.state.subscriptionInfo.length > 0 ? (
          <table>
            <tbody>
              {this.state.subscriptionInfo.map(function(item) {
                return (
                  <tr>
                    <td className="solidBorder">
                      {me.displaySubscription(item)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <h3>You don't have any subscriptions at this time.</h3>
        )}
      </div>
    );
  }
}

export default SubscriptionInformation;
