import React, { Component } from "react";
import BigCalendar from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ScheduledGame from "./scheduledgame.js";
import ScheduledGamePage from "./scheduledGameComponent.js";

class GamesCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scoreboardOnline: props.scoreboardOnline,
      apiRepo: props.scoreboardOnline.apiRepo,
      page: props.page,
      gameId: -1
    };
  }

  handleSelectGame = event => {
    this.setState({
      page: "savedGamesPage",
      gameId: event.id
    });
  };

  handleSelectSlot = ({ start, end }) => {
    let newGame = new ScheduledGame();
    newGame.datePlayed = start;
    newGame.endTime = end;
    this.state.scoreboardOnline.addSavedGame(newGame);

    this.setState({
      page: "savedGamesPage",
      scoreboardOnline: this.state.scoreboardOnline,
      gameId: newGame.gameId
    });
  };

  refreshMethod = () => {
    this.setState({
      page: "gameCalendar",
      gameId: -1
    });
  };

  savedGamesPage() {
    let scheduledGame = this.state.scoreboardOnline.getSavedGame(
      this.state.gameId
    );

    return (
      <ScheduledGamePage
        key="ScheduledGamePage"
        scoreboardOnline={this.state.scoreboardOnline}
        scheduledGame={new ScheduledGame(scheduledGame)}
        refreshMethod={this.refreshMethod}
      />
    );
  }

  editGame = gameId => {
    this.setState({
      page: "savedGamesPage",
      gameId: gameId
    });
  };

  unscheduledGameList = game => {
    return (
      <tr>
        <td className="tableCellLeft gameNameWidth">
          {this.state.scoreboardOnline.getGameName(game.gameType)}
        </td>
        <td className="tableCellCenter gameNameWidth">
          {game.teams.map(function(item) {
            return (
              <div>
                {item.teamName}
                <br />
              </div>
            );
          })}
        </td>
        <td className="tableCellRight">
          <input
            type="button"
            value="Edit"
            onClick={() => this.editGame(game.gameId)}
          />
        </td>
      </tr>
    );
  };

  unscheduledGames() {
    let me = this;

    return (
      <table className="tableBorderCollapse">
        <thead>
          <tr>
            <th className="gameNameWidth">Game Type</th>
            <th>Teams</th>
          </tr>
        </thead>
        <tbody>
          {this.state.scoreboardOnline
            .getUnscheduledGames()
            .map(function(item) {
              return me.unscheduledGameList(item);
            })}
        </tbody>
      </table>
    );
  }

  gameCalendar() {
    let allViews = Object.keys(BigCalendar.Views).map(
      k => BigCalendar.Views[k]
    );

    return (
      <div className="App" key="BigCalendar">
        <BigCalendar
          selectable
          defaultDate={new Date()}
          defaultView="month"
          views={allViews}
          step={60}
          showMultiDayTimes
          events={this.state.scoreboardOnline.getAllEvents()}
          style={{ height: "90vh" }}
          localizer={BigCalendar.momentLocalizer(moment)}
          onSelectEvent={this.handleSelectGame}
          onSelectSlot={this.handleSelectSlot}
        />
      </div>
    );
  }

  render() {
    if (this.state.page === "savedGamesPage") {
      return this.savedGamesPage();
    } else if (this.state.page === "unscheduledGames") {
      return this.unscheduledGames();
    } else {
      return this.gameCalendar();
    }
  }
}

export default GamesCalendar;
