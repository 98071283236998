import React, { Component } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import AccountForm from "./accountFormComponent.js";
import SubscriptionInformation from "./subscriptionInformationComponent.js";
import stripeLogo2 from "./images/powered_by_stripe_dark.png";

class AccountPages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scoreboardOnline: props.scoreboardOnline,
      callMethod: props.callMethod,
      parentRefreshMethod: props.refreshMethod,
      page: props.page
    };
  }

  loginPage() {
    return (
      <form id="loginForm" onSubmit={this.loginMethod}>
        <table>
          <tbody>
            <tr>
              <td>User Name:</td>
              <td>
                <input type="text" id="username" name="username" />
              </td>
            </tr>
            <tr>
              <td>Password: </td>
              <td>
                <input type="password" id="password" name="password" />
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="submit"
                  value="Login"
                  ref={function(node) {
                    this.loginButton = node;
                  }.bind(this)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  }

  loginMethod = event => {
    event.preventDefault();

    this.loginButton.disabled = true;

    const formData = new FormData(event.target);
    let elementNames = ["username", "password"];

    this.state.scoreboardOnline.apiRepo.login(
      formData,
      elementNames,
      this.loginReturnMethod,
      this.loginErrorMethod
    );
  };

  loginReturnMethod = resultsJson => {
    let scoreboardOnline = this.state.scoreboardOnline;

    scoreboardOnline.username = resultsJson.username;
    scoreboardOnline.password = resultsJson.password;
    scoreboardOnline.userId = resultsJson.userId;
    scoreboardOnline.firstName = resultsJson.firstName;
    scoreboardOnline.lastName = resultsJson.lastName;
    scoreboardOnline.eMail = resultsJson.eMail;

    if (this.state.callMethod) {
      scoreboardOnline.isLoggedIn = true;
      scoreboardOnline.showLogin = false;
      scoreboardOnline.accountForm = false;
      scoreboardOnline.actionPage = 0;

      if (this.state.page === "newAccount") {
        this.state.scoreboardOnline.setActionCurrentPage(8);
      } //else {
      this.state.callMethod(scoreboardOnline);
      //}
    } else {
      if (this.submitButton) {
        this.submitButton.disabled = false;
        this.clearButton.disabled = false;
      }

      this.state.parentRefreshMethod(scoreboardOnline);
    }
  };

  accountForm() {
    return (
      <div key="newAccount">
        <form
          onSubmit={this.validateCreateUser}
          ref={function(node) {
            this.userForm = node;
          }.bind(this)}
        >
          {!this.state.scoreboardOnline.isLoggedIn && <h1>Create Account</h1>}
          <table>
            {!this.state.scoreboardOnline.isLoggedIn && (
              <thead>
                <tr>
                  <th colSpan="3">All fields are required.</th>
                </tr>
              </thead>
            )}
            <tbody>
              <tr>
                <td className="smallCell">
                  <label htmlFor="firstName">First Name:</label>
                </td>
                <td className="smallCell">
                  <label
                    htmlFor="firstName"
                    className="hiddenError"
                    ref={function(node) {
                      this.firstNameError = node;
                    }.bind(this)}
                  >
                    *
                  </label>
                </td>
                <td className="smallCell">
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    defaultValue={this.state.scoreboardOnline.firstName}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="lastName">Last Name:</label>
                </td>
                <td>
                  <label
                    htmlFor="lastName"
                    className="hiddenError"
                    ref={function(node) {
                      this.lastNameError = node;
                    }.bind(this)}
                  >
                    *
                  </label>
                </td>
                <td>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    defaultValue={this.state.scoreboardOnline.lastName}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="username">User Name:</label>
                </td>
                <td>
                  <label
                    htmlFor="username"
                    className="hiddenError"
                    ref={function(node) {
                      this.userNameError = node;
                    }.bind(this)}
                  >
                    *
                  </label>
                </td>
                <td>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    defaultValue={this.state.scoreboardOnline.username}
                    readOnly={this.state.scoreboardOnline.isLoggedIn}
                    onKeyUp={this.searchUsername}
                    className={
                      this.state.scoreboardOnline.isLoggedIn ? "noBorder" : ""
                    }
                    ref={function(node) {
                      this.username = node;
                    }.bind(this)}
                  />
                </td>
                <td colSpan="2">
                  <label
                    className="hiddenError"
                    htmlFor="username"
                    ref={function(node) {
                      this.usernameHiddenError = node;
                    }.bind(this)}
                  >
                    Username is already taken.
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="eMail">E-mail:</label>
                </td>
                <td>
                  <label
                    htmlFor="eMail"
                    className="hiddenError"
                    ref={function(node) {
                      this.eMailError = node;
                    }.bind(this)}
                  >
                    *
                  </label>
                </td>
                <td>
                  <input
                    type="email"
                    id="eMail"
                    name="eMail"
                    defaultValue={this.state.scoreboardOnline.eMail}
                    onKeyUp={this.searchEmail}
                    ref={function(node) {
                      this.email = node;
                    }.bind(this)}
                  />
                </td>
                <td className="alignLeft">
                  <input type="button" value="?" onClick={this.emailReason} />
                </td>
                <td>
                  <label
                    className="hiddenError"
                    htmlFor="eMail"
                    ref={function(node) {
                      this.emailError = node;
                    }.bind(this)}
                  >
                    Email address is already taken.
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="password">Password:</label>
                </td>
                <td>
                  <label
                    htmlFor="password"
                    className="hiddenError"
                    ref={function(node) {
                      this.passwordError = node;
                    }.bind(this)}
                  >
                    *
                  </label>
                </td>
                <td>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    onKeyUp={this.validatePassword}
                    ref={function(node) {
                      this.password = node;
                    }.bind(this)}
                  />
                </td>
                {this.state.scoreboardOnline.isLoggedIn && (
                  <td>
                    <input
                      type="button"
                      value="?"
                      onClick={this.changePassword}
                    />
                  </td>
                )}
                <td colSpan="2">
                  <label
                    className="hiddenError"
                    htmlFor="password2"
                    ref={function(node) {
                      this.hiddenError = node;
                    }.bind(this)}
                  >
                    Password must be at least 8 letters long.
                  </label>
                </td>
              </tr>
              <tr>
                <td className="nowrap">
                  <label htmlFor="password2">Retype Password:</label>
                </td>
                <td />
                <td>
                  <input
                    type="password"
                    id="password2"
                    name="password2"
                    onKeyUp={this.validatePassword}
                    ref={function(node) {
                      this.password2 = node;
                    }.bind(this)}
                  />
                </td>
                <td colSpan="2">
                  <label
                    className="hiddenError"
                    htmlFor="password2"
                    ref={function(node) {
                      this.hiddenError2 = node;
                    }.bind(this)}
                  >
                    * Passwords don't match.
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="submit"
                    value={
                      this.state.scoreboardOnline.isLoggedIn ? "Submit" : "Next"
                    }
                    ref={function(node) {
                      this.submitButton = node;
                    }.bind(this)}
                  />
                </td>
                <td />
                <td>
                  <input
                    type="button"
                    value={
                      this.state.scoreboardOnline.isLoggedIn ? "Reset" : "Clear"
                    }
                    onClick={this.clearForm}
                    ref={function(node) {
                      this.clearButton = node;
                    }.bind(this)}
                  />
                </td>
              </tr>
              {this.state.scoreboardOnline.isLoggedIn && (
                <tr>
                  <td colSpan="3">
                    <input
                      type="button"
                      value="Stripe Payments"
                      onClick={this.stripePaymentShowHide}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </form>
      </div>
    );
  }

  stripePaymentShowHide = () => {
    this.setState({ page: "accountPayments" });
  };

  searchUsername = () => {
    this.state.scoreboardOnline.apiRepo.searchUsername(
      this.username.value,
      this.searchUsernameReturnMethod,
      null
    );
  };

  searchUsernameReturnMethod = resultsJson => {
    if (resultsJson.username === "available") {
      this.usernameHiddenError.className = "shownNotification";
      this.usernameHiddenError.innerText = "Username is available.";
    } else {
      this.usernameHiddenError.className = "shownError";
      this.usernameHiddenError.innerText = "Username is already taken.";
    }
  };

  searchEmail = () => {
    this.state.scoreboardOnline.apiRepo.searchEmail(
      this.email.value,
      this.searchEmailReturnMethod,
      null
    );
  };

  searchEmailReturnMethod = resultsJson => {
    if (resultsJson.email === "available") {
      this.emailError.className = "hiddenError";
    } else {
      this.emailError.className = "shownError";
    }
  };

  changePassword = () => {
    alert("Only enter a password if you want to change it.");
  };

  loginErrorMethod = () => {
    this.loginButton.disabled = false;

    alert("Unable to log you in. Please retry or create an account.");
  };

  newAccountMethod = formData => {
    let elementNames = [
      "username",
      "firstName",
      "lastName",
      "eMail",
      "password"
    ];

    if (this.state.scoreboardOnline.isLoggedIn) {
      this.state.scoreboardOnline.apiRepo.updateUser(
        this.state.scoreboardOnline.userId,
        formData,
        elementNames,
        this.loginReturnMethod,
        this.addUserErrorMethod
      );
    } else {
      this.state.scoreboardOnline.apiRepo.addUser(
        formData,
        elementNames,
        this.loginReturnMethod,
        this.addUserErrorMethod
      );
    }
  };

  addUserErrorMethod = () => {
    alert("Unable to create an account.");
  };

  validateCreateUser = event => {
    event.preventDefault();

    let formData = new FormData(event.target);
    let form = this.userForm;
    let hasError = false;

    if (form["firstName"].value.trim() === "") {
      this.firstNameError.className = "shownError";
      hasError = true;
    } else {
      this.firstNameError.className = "hiddenError";
    }

    if (form["lastName"].value.trim() === "") {
      this.lastNameError.className = "shownError";
      hasError = true;
    } else {
      this.lastNameError.className = "hiddenError";
    }

    if (
      !this.state.scoreboardOnline.isLoggedIn &&
      form["username"].value.trim() === ""
    ) {
      this.userNameError.className = "shownError";
      hasError = true;
    } else {
      this.userNameError.className = "hiddenError";
    }

    if (form["eMail"].value.trim() === "" || !form["eMail"].checkValidity()) {
      this.eMailError.className = "shownError";
      hasError = true;
    } else {
      this.eMailError.className = "hiddenError";
    }

    if (!this.state.scoreboardOnline.isLoggedIn) {
      if (form["password"].value.trim() === "") {
        this.passwordError.className = "shownError";
        hasError = true;
      } else {
        this.passwordError.className = "hiddenError";
      }
    }

    if (
      form["password"].value.trim() !== "" ||
      !this.state.scoreboardOnline.isLoggedIn
    ) {
      if (form["password"].value.trim() !== form["password2"].value.trim()) {
        hasError = true;
        this.hiddenError2.className = "shownError";
      } else {
        this.hiddenError2.className = "hiddenError";
      }

      if (this.password.value.trim().length < 8) {
        hasError = true;
        this.hiddenError.className = "shownError";
      } else {
        this.hiddenError.className = "hiddenError";
      }
    }

    if (!hasError) {
      this.submitButton.disabled = true;
      this.clearButton.disabled = true;
      this.newAccountMethod(formData);
    }
  };

  validatePassword = () => {
    if (this.password.value.trim().length < 8) {
      this.hiddenError.className = "shownError";
    } else {
      this.hiddenError.className = "hiddenError";
    }

    if (
      this.password2.value.trim() !== "" &&
      this.password.value !== this.password2.value
    ) {
      this.hiddenError2.className = "shownError";
    } else {
      this.hiddenError2.className = "hiddenError";
    }
  };

  clearForm = () => {
    this.userForm.reset();
  };

  emailReason = () => {
    alert(
      "Your email address is required for notification and account verification purposes."
    );
  };

  refreshMethod = () => {
    this.setState({
      scoreboardOnline: this.state.scoreboardOnline
    });
  };

  // noinspection JSMethodCanBeStatic
  accountPaymentForm() {
    let stripeKey = "pk_test_fXeW7axblBRBXBgWRppIu6Z9"; // test key, swap with real key for live site

    return (
      <div key={new Date()}>
        <SubscriptionInformation
          scoreboardOnline={this.state.scoreboardOnline}
        />
        <br />
        <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">
          <img src={stripeLogo2} alt="Stripe.com" />
        </a>
        <StripeProvider apiKey={stripeKey}>
          <Elements>
            <AccountForm refreshMethod={this.refreshMethod} />
          </Elements>
        </StripeProvider>
      </div>
    );
  }

  render() {
    switch (this.state.page) {
      case "newAccount":
        return this.accountForm();
      case "updateAccount":
        return this.accountForm();
      case "accountPayments":
        return this.accountPaymentForm();
      default:
        return this.loginPage();
    }
  }
}

export default AccountPages;
