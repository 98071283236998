// eslint-disable-next-line
class Subscription {
  constructor(data) {
    if (data) {
      this.subscriptionStart = new Date(data.subscriptionStart);
      this.isStripe = data.isStripe;
      this.amount = data.amount;
      this.currency = data.currency.toUpperCase();
      this.failureMessage = data.failureMessage;
      this.status = data.status;
      this.refundedStatus = data.refundedStatus;
      this.brand = data.brand;
      this.last4 = data.last4;
      this.expirationMonth = data.expirationMonth;
      this.expirationYear = data.expirationYear;
      this.nextRenewalDate = new Date(data.nextRenewalDate);
      this.subscriptionId = data.subscriptionId;
      this.active = data.active;
    } else {
      this.subscriptionStart = null;
      this.isStripe = false;
      this.amount = 25;
      this.currency = "USD";
      this.failureMessage = "";
      this.status = "";
      this.refundedStatus = false;
      this.brand = "";
      this.last4 = "";
      this.expirationMonth = "";
      this.expirationYear = "";
      this.nextRenewalDate = null;
      this.subscriptionId = "";
      this.active = false;
    }
  }
}

export default Subscription;
