import React, { Component } from "react";
import { PhotoshopPicker } from "react-color";
import TeamInformation from "./teamInformation";
import TeamPermission from "./teampermission";

// eslint-disable-next-line
class TeamPage extends Component {
  constructor(props) {
    super(props);

    let teamInfo = props.scoreboardOnline.cloneCurrentTeam();
    let gameType = teamInfo ? teamInfo.getGameType() : 0;

    this.state = {
      scoreboardOnline: props.scoreboardOnline,
      teamInfo: props.scoreboardOnline.cloneCurrentTeam(),
      scoreColor: false,
      teamColor: false,
      backgroundColor: "#000000",
      newBackground: "",
      gameType: gameType,
      teamSearchId: null,
      parentRefreshMethod: props.refreshMethod,
      teamNamesLoaded: false
    };
  }

  teamPage() {
    let teamInfo = this.state.teamInfo;
    let nameStyle = { backgroundColor: teamInfo.getNameColor() };
    let scoreColorStyle = { backgroundColor: teamInfo.getScoreColor() };
    let existingGamesList = this.state.scoreboardOnline.getExistingTeamNames();
    let defaultTeamSearch = "";
    let editable = false;

    if (teamInfo.isMyTeam()) {
      editable =
        this.state.teamSearchId === null &&
        this.state.scoreboardOnline.isScorekeeper(teamInfo.getTeamId());
    }

    if (this.state.teamSearchId) {
      defaultTeamSearch = this.state.teamSearchId;
    }

    if ((!this.state.teamNamesLoaded) && (teamInfo.getTeamId()) < 0 && (existingGamesList.length === 1)) {
      this.getTeamNames();
    }

    return (
      <form
        id="addTeam"
        onSubmit={this.saveTeamInfo}
        ref={function(node) {
          this.teamForm = node;
        }.bind(this)}
      >
        <input
          type="hidden"
          name="userId"
          value={this.state.scoreboardOnline.userId}
        />

        <input type="hidden" name="myTeam" value={teamInfo.isMyTeam()} />
        <input type="hidden" name="teamId" value={teamInfo.getTeamId()} />
        <input type="hidden" name="nameColor" value={teamInfo.getNameColor()} />
        <input
          type="hidden"
          name="scoreColor"
          value={teamInfo.getScoreColor()}
        />
        <table key={teamInfo.getTeamId()}>
          <tbody>
            {(teamInfo.getTeamId() < 0 || teamInfo.isMyTeam() === null) && (
              <tr>
                <td>Team Search:</td>
                <td>
                  <select
                    value={defaultTeamSearch}
                    name="existingTeam"
                    onChange={this.getTeamInformation}
                  >
                    {existingGamesList.map(function(item) {
                      return (
                        <option value={item.teamId}>{item.teamName}</option>
                      );
                    })}
                  </select>
                  <input
                    type="button"
                    value="Clear"
                    onClick={this.clearSearch}
                  />
                </td>
              </tr>
            )}
            <tr>
              <td>
                Team Name:
                {editable && <label className="required">*</label>}
              </td>
              <td>
                <input
                  type="text"
                  name="teamName"
                  //defaultValue={(editable && (teamInfo.getTeamId() >= 0)) ? teamInfo.getTeamName() : ""}
                  defaultValue={teamInfo.getTeamName()}
                  className={editable ? "" : "noBorder"}
                  readOnly={!editable}
                />
              </td>
            </tr>
            <tr>
              <td>Name Color: </td>
              {editable ? (
                <td>
                  <div className="fullWidth">
                    <input
                      type="button"
                      value="Click to change"
                      style={nameStyle}
                      name="nameColorButton"
                      onClick={() => {
                        this.setColorPicker(1, teamInfo.getNameColor());
                      }}
                    />
                    <input
                      type="button"
                      value="?"
                      onClick={() => {
                        alert(
                          "This is the color for the team name on the score card."
                        );
                      }}
                    />
                  </div>
                </td>
              ) : (
                <td style={nameStyle} />
              )}
            </tr>
            <tr>
              <td>Score Color: </td>
              {editable ? (
                <td>
                  <div className="fullWidth">
                    <input
                      type="button"
                      value="Click to change"
                      style={scoreColorStyle}
                      name="scoreColorButton"
                      onClick={() => {
                        this.setColorPicker(2, teamInfo.getScoreColor());
                      }}
                    />
                    <input
                      type="button"
                      value="?"
                      onClick={() => {
                        alert(
                          "This is the color for the team score on the score card."
                        );
                      }}
                    />
                  </div>
                </td>
              ) : (
                <td style={scoreColorStyle} />
              )}
            </tr>
            <tr>
              <td>Game Type:</td>
              <td>
                {editable ? (
                  <select defaultValue={teamInfo.getGameType()} name="gameType">
                    {this.state.scoreboardOnline.gameTypes.map(function(item) {
                      return <option value={item.id}>{item.gameName}</option>;
                    })}
                  </select>
                ) : (
                  <label>
                    {this.state.scoreboardOnline.getGameName(
                      teamInfo.getGameType()
                    )}
                  </label>
                )}
              </td>
            </tr>
            <tr>
              <td className="alignTop">Team Coach:</td>
              <td className="alignTop">
                {teamInfo.coaches.length > 0 && (
                  <table>
                    <thead>
                      {teamInfo.coaches.map(function(item) {
                        return (
                          <tr>
                            <td>{item.fullName}</td>
                          </tr>
                        );
                      })}
                    </thead>
                  </table>
                )}
                {editable && (
                  <input
                    type="button"
                    value="?"
                    onClick={() => {
                      alert("Manage coaches on the Users page.");
                    }}
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>Team School:</td>
              <td>
                <input
                  type="text"
                  name="schoolName"
                  defaultValue={teamInfo.getSchoolName()}
                  className={editable ? "" : "noBorder"}
                  readOnly={!editable}
                />
              </td>
            </tr>
            <tr>
              <td>Team Address:</td>
              <td>
                <input
                  type="text"
                  name="teamAddress"
                  defaultValue={teamInfo.getTeamAddress()}
                  className={editable ? "" : "noBorder"}
                  readOnly={!editable}
                />
              </td>
            </tr>
            <tr>
              <td>Team City:</td>
              <td>
                <input
                  type="text"
                  name="teamCity"
                  defaultValue={teamInfo.getTeamCity()}
                  className={editable ? "" : "noBorder"}
                  readOnly={!editable}
                />
              </td>
            </tr>
            <tr>
              <td>Team State:</td>
              <td>
                <input
                  type="text"
                  name="teamState"
                  defaultValue={teamInfo.getTeamState()}
                  className={editable ? "" : "noBorder"}
                  readOnly={!editable}
                />
              </td>
            </tr>
            <tr>
              <td>Team Zip code:</td>
              <td>
                <input
                  type="text"
                  name="teamZipCode"
                  defaultValue={teamInfo.getTeamZipCode()}
                  className={editable ? "" : "noBorder"}
                  readOnly={!editable}
                />
              </td>
            </tr>
            <tr>
              <td>Team Phone:</td>
              <td>
                <input
                  type="phone"
                  name="phoneNumber"
                  defaultValue={teamInfo.getPhoneNumber()}
                  className={editable ? "" : "noBorder"}
                  readOnly={!editable}
                />
              </td>
            </tr>
            <tr>
              <td>Alternate Phone:</td>
              <td>
                <input
                  type="phone"
                  defaultValue={teamInfo.getAlternatePhoneNumber()}
                  name="alternatePhoneNumber"
                  className={editable ? "" : "noBorder"}
                  readOnly={!editable}
                />
              </td>
            </tr>
            <tr>
              <td>Team Email:</td>
              <td>
                <input
                  type="email"
                  name="teamEmail"
                  defaultValue={teamInfo.getTeamEmail()}
                  className={editable ? "" : "noBorder"}
                  readOnly={!editable}
                />
              </td>
            </tr>
            <tr>
              <td>Team Website:</td>
              <td>
                <input
                  type="text"
                  name="teamSite"
                  defaultValue={teamInfo.getTeamSite()}
                  className={editable ? "" : "noBorder"}
                  readOnly={!editable}
                />
              </td>
            </tr>
            {teamInfo.getTeamId() < 0 && this.state.teamSearchId !== null ? (
              <tr>
                <td colSpan="2">
                  <input
                    type="button"
                    value="Follow Team"
                    onClick={this.followTeam}
                  />
                </td>
              </tr>
            ) : (
              <tr>
                <td>{editable && <input type="submit" value="Save" />}</td>
                <td>
                  {editable && (
                    <input
                      type="button"
                      value="Cancel"
                      onClick={this.refreshPage}
                    />
                  )}
                  {teamInfo.getTeamId() >= 0 && (
                    <input
                      type="button"
                      value="Unfollow Team"
                      onClick={this.unfollowTeam}
                    />
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </form>
    );
  }

  refreshPage = () => {
    this.state.teamInfo.setTeamId(this.state.teamInfo.getTeamId() * -1);

    this.setState({
      teamInfo: this.state.teamInfo
    });
  };

  unfollowTeam = () => {
    let scoreboardOnline = this.state.scoreboardOnline;

    this.state.scoreboardOnline.apiRepo.updateTeamUsers(
      scoreboardOnline.getCurrentTeamId(),
      [scoreboardOnline.userId],
      "remove",
      this.unfollowTeamReturnMethod,
      this.unfollowTeamErrorMethod
    );
  };

  unfollowTeamErrorMethod = () => {
    alert(
      "Unable to unfollow team. Please retry if this is the first time you've seen this error."
    );
  };

  unfollowTeamReturnMethod = () => {
    let scoreboardOnline = this.state.scoreboardOnline;
    let team = scoreboardOnline.getCurrentTeam();

    scoreboardOnline.setCurrentTeam(-1 * team.getTeamId());
    team.setTeamId(-1 * team.getTeamId());

    this.state.parentRefreshMethod(scoreboardOnline);

    this.setState({
      scoreboardOnline: scoreboardOnline,
      teamSearchId: Math.abs(team.getTeamId()) + ""
    });
  };

  followTeam = () => {
    let scoreboardOnline = this.state.scoreboardOnline;
    let team = scoreboardOnline.getCurrentTeam();

    let bodyContent = {
      teamId: Math.abs(team.getTeamId()),
      myTeam: team.isMyTeam()
    };

    this.state.scoreboardOnline.apiRepo.followTeam(
      bodyContent,
      this.followTeamReturnMethod,
      this.followTeamErrorMethod
    );
  };

  followTeamErrorMethod = () => {
    alert(
      "Unable to add team. Please retry if this is the first time you've seen this error."
    );
  };

  followTeamReturnMethod = resultsJson => {
    let scoreboardOnline = this.state.scoreboardOnline;
    let team = scoreboardOnline.getTeam(-1 * resultsJson.teamId);

    if (scoreboardOnline.getCurrentTeamId() === team.getTeamId()) {
      scoreboardOnline.setCurrentTeam(resultsJson.teamId);
    }

    team.setTeamId(Math.abs(resultsJson.teamId));

    this.state.parentRefreshMethod(scoreboardOnline);

    this.setState({
      scoreboardOnline: scoreboardOnline,
      teamSearchId: null
    });
  };

  getTeamNames = () => {
    this.state.scoreboardOnline.apiRepo.getTeamNames(
      this.getTeamNamesReturnMethod,
      this.getTeamNamesErrorMethod
    );
  };

  getTeamNamesErrorMethod = () => {
    alert(
      "Unable to load existing teams. Please retry if this is the first time you've seen this error."
    );
  };

  getTeamNamesReturnMethod = resultsJson => {
    let scoreboardOnline = this.state.scoreboardOnline;

    if (resultsJson !== null && resultsJson !== undefined) {
      for (let i = 0; i < resultsJson.length; i++) {
        scoreboardOnline.addLoadedTeam(resultsJson[i]);
      }
    }

    this.setState({
      scoreboardOnline: scoreboardOnline,
      teamNamesLoaded: true
    });
  };

  clearSearch = () => {
    let scoreboardOnline = this.state.scoreboardOnline;

    if (scoreboardOnline.containsTeamId(-0.1)) {
      scoreboardOnline.setCurrentTeam(-0.1);
    }

    this.setState({
      scoreboardOnline: scoreboardOnline,
      teamSearchId: null
    });
  };

  getTeamInformation = event => {
    if (event.target.value >= 0) {
      this.state.scoreboardOnline.apiRepo.getTeam(
        event.target.value,
        this.getTeamInformationReturnMethod,
        this.getTeamInformationErrorMethod
      );

      this.setState({ teamSearchId: event.target.value });
    }
  };

  getTeamInformationErrorMethod = () => {
    alert(
      "Unable to get team information. Please retry if this is the first time you've seen this error."
    );
  };

  getTeamInformationReturnMethod = resultsJson => {
    let scoreboardOnline = this.state.scoreboardOnline;
    let newTeam = new TeamInformation(resultsJson[0]);

    newTeam.setMyTeam(scoreboardOnline.getCurrentTeam().isMyTeam());
    newTeam.setTeamId(newTeam.getTeamId() * -1);
    scoreboardOnline.addTeam(newTeam);
    scoreboardOnline.setCurrentTeam(newTeam.getTeamId());

    let teamInfo = this.state.scoreboardOnline.cloneCurrentTeam();

    this.setState({
        scoreboardOnline: scoreboardOnline,
        teamInfo: teamInfo
    });
  };

  // noinspection JSMethodCanBeStatic
  getElementNames() {
    return [
      "teamId",
      "teamName",
      "nameColor",
      "scoreColor",
      "gameType",
      "schoolName",
      "teamAddress",
      "phoneNumber",
      "alternatePhoneNumber",
      "teamEmail",
      "teamSite",
      "userId",
      "myTeam",
      "teamCity",
      "teamState",
      "teamZipCode"
    ];
  }

  saveTeamInfo = event => {
    event.preventDefault();
    let teamForm = this.teamForm;
    let errors = false;

    this.updateTeamForm();

    if (teamForm.teamName.value === null || teamForm.teamName.value === "") {
      errors = true;
    }

    if (!teamForm.teamEmail.checkValidity()) {
      errors = true;
    }

    if (!errors) {
      const formData = new FormData(event.target);
      let elementNames = this.getElementNames();

      this.state.scoreboardOnline.apiRepo.saveTeamInfo(
        formData,
        elementNames,
        this.saveTeamReturnMethod,
        this.saveTeamErrorMethod
      );
    } else {
      alert(
        "Please check the Team Name and the Email address for complete information."
      );
    }
  };

  saveTeamErrorMethod = () => {
    alert(
      "Unable to save team. Please retry if this is the first time you've seen this error."
    );
  };

  saveTeamReturnMethod = resultsJson => {
    let scoreboardOnline = this.state.scoreboardOnline;
    let teamPermissions = new TeamPermission(resultsJson[0]);
    let existingTeam = scoreboardOnline.getTeam(teamPermissions.teamId);

    if (existingTeam !== null) {
      existingTeam.updateTeam(this.state.teamInfo);
    } else {
      this.state.teamInfo.setTeamId(teamPermissions.teamId);
      scoreboardOnline.addTeam(this.state.teamInfo);
      scoreboardOnline.setCurrentTeam(teamPermissions.teamId);

      scoreboardOnline.addTeamPermissions(teamPermissions);
    }

    this.state.parentRefreshMethod(scoreboardOnline);

    this.setState({
      scoreboardOnline: scoreboardOnline,
      teamInfo: this.state.teamInfo
    });
  };

  updateTeamForm() {
    let teamInfo = this.state.teamInfo;
    let elementNames = this.getElementNames();
    let teamForm = this.teamForm;

    elementNames.forEach(function(item) {
      let value = teamForm[item].value;
      if (value !== null && value !== "" && isFinite(value)) {
        teamInfo[item] = value * 1;
      } else {
        if (value.toLowerCase() === "true") {
          teamInfo[item] = true;
        } else if (value.toLowerCase() === "false") {
          teamInfo[item] = false;
        } else {
          teamInfo[item] = value;
        }
      }
    });
  }

  setColorPicker(colorType, color) {
    this.updateTeamForm();

    this.setState({
      scoreboardOnline: this.state.scoreboardOnline,
      teamColor: colorType === 1,
      scoreColor: colorType === 2,
      backgroundColor: color,
      teamInfo: this.state.teamInfo
    });
  }

  handleChangeComplete = color => {
    this.setState({
      backgroundColor: color.hex
    });
  };

  setClick() {
    if (this.state.teamColor) {
      this.state.teamInfo.setNameColor(this.state.backgroundColor);
    } else if (this.state.scoreColor) {
      this.state.teamInfo.setScoreColor(this.state.backgroundColor);
    }

    this.setState({
      teamInfo: this.state.teamInfo,
      scoreColor: false,
      teamColor: false
    });
  }

  cancelClick() {
    this.setState({
      scoreboardOnline: this.state.scoreboardOnline,
      scoreColor: false,
      teamColor: false
    });
  }

  colorPicker = title => {
    return (
      <div key={title}>
        <PhotoshopPicker
          onChangeComplete={this.handleChangeComplete}
          color={this.state.backgroundColor}
          header={title}
          onAccept={this.setClick.bind(this)}
          onCancel={this.cancelClick.bind(this)}
        />
      </div>
    );
  };

  updateTeamInfo = () => {
    let teamInfo = this.state.scoreboardOnline.cloneCurrentTeam();

    this.setState({
      teamInfo: teamInfo,
      teamSearchId: null
    });
  };

  render() {
    if (
      this.state.scoreboardOnline.getCurrentTeamId() !==
      this.state.teamInfo.getTeamId()
    ) {
      this.updateTeamInfo();
    }

    if (this.state.teamColor) {
      return this.colorPicker("Name Color");
    } else if (this.state.scoreColor) {
      return this.colorPicker("Score Color");
    } else {
      return this.teamPage();
    }
  }
}

export default TeamPage;
