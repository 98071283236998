// eslint-disable-next-line
class TeamName {
  constructor(newTeam) {
    if (newTeam) {
      this.teamId = newTeam.teamId;
      this.teamName = newTeam.teamName;
    } else {
      this.teamId = 0;
      this.teamName = "HOME";
    }
  }

  getTeamId() {
    return this.teamId;
  }
}

export default TeamName;
