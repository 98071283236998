import React, { Component } from "react";
import { render } from "react-dom";
import "./App.css";
import PageGenerator from "./pageGenerator.js";
import ScoreboardOnline from "./scoreboardOnline.js";
import TeamInformation from "./teamInformation.js";
import GameType from "./gameType.js";
import TeamPermission from "./teampermission.js";
import ScheduledGame from "./scheduledgame.js";
import logo from "./images/ScoreBoardBanner.svg";

class Scoreboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scoreboardOnline: new ScoreboardOnline(),
      preloadComplete: false
    };
  }

  showLogin = () => {
    let scoreboardOnline = this.state.scoreboardOnline;

    scoreboardOnline.showLogin = true;

    this.setState({ scoreboardOnline: scoreboardOnline });
  };

  logOut = () => {
    let scoreboardOnline = this.state.scoreboardOnline;

    scoreboardOnline.isLoggedIn = false;
    scoreboardOnline.showLogin = false;

    this.setState({
      scoreboardOnline: new ScoreboardOnline(),
      preloadComplete: false
    });
  };

  newAccount = () => {
    let scoreboardOnline = this.state.scoreboardOnline;

    scoreboardOnline.accountForm = true;
    scoreboardOnline.showLogin = false;

    this.setState({ scoreboardOnline: scoreboardOnline });
  };

  showHideMenu = () => {
    this.userMenu.className =
      this.userMenu.className === "shownMenu" ? "hiddenMenu" : "shownMenu";
  };

  openEricsGear = () => {
    window.open("http://EricsGear.com", "_blank");
  };

  editAccount = () => {
    this.showHideMenu();
    this.setActionPage(7);
  };

  editPayment = () => {
    this.showHideMenu();
    this.setActionPage(8);
  };

  appHeader = () => {
    return (
      <div
        key={
          "appHeader" +
          (this.state.scoreboardOnline.isLoggedIn ? "login" : "loggedIn")
        }
      >
        <div className="container">
          <div className="logoDiv">
            <img src={logo} className="mainLogo" alt="Scoreboard - EG" />
          </div>
          <div className="headerText">
            The
            <br />
            Online
            <br />
            Portal
          </div>
          {!this.state.scoreboardOnline.isLoggedIn && (
            <div className="floatRight flexGrow">
              <label onClick={this.showLogin} className="handCursor">
                Login
              </label>
              <br />
              <label onClick={this.newAccount} className="handCursor smallFont">
                Create Account
              </label>
            </div>
          )}
          {this.state.scoreboardOnline.isLoggedIn && (
            <div className="floatRight flexGrow">
              <label onClick={this.showHideMenu} className="handCursor">
                Hello {this.state.scoreboardOnline.firstName}.
              </label>
              <div
                className="hiddenMenu"
                ref={function(node) {
                  this.userMenu = node;
                }.bind(this)}
              >
                <label className="handCursor" onClick={this.editAccount}>
                  Edit Account
                </label>
                <br />
                <label className="handCursor" onClick={this.editPayment}>
                  Edit/View Payment Info
                </label>
                <br />
                <a href="./tac.html" target="_blank">
                  Terms and Conditions of Use
                </a>
                <br />
                <label className="handCursor" onClick={this.logOut}>
                  Logout
                </label>
              </div>
            </div>
          )}
        </div>
        <label className="ericsGear" onClick={this.openEricsGear}>
          By Eric's Gear
        </label>
      </div>
    );
  };

  actionPage = (pageName, callMethod) => {
    return (
      <div className="mainPanel" key={pageName}>
        <PageGenerator
          page={pageName}
          callMethod={callMethod}
          scoreboardOnline={this.state.scoreboardOnline}
          refreshMethod={this.refreshMethod}
        />
      </div>
    );
  };

  setActionPage(actionPage, teamId) {
    let scoreboardOnline = this.state.scoreboardOnline;

    if (teamId < 0) {
      let newTeam = scoreboardOnline.getTeam(teamId);

      if (!newTeam) {
        newTeam = new TeamInformation();
        newTeam.setTeamId(teamId);

        scoreboardOnline.addTeam(newTeam);
      }

      newTeam.setMyTeam(teamId === -0.1);
    }

    scoreboardOnline.setActionCurrentPage(actionPage);
    scoreboardOnline.setCurrentTeam(teamId);
    this.setState({ scoreboardOnline: scoreboardOnline });
  }

  setCurrentPage(pageNumber) {
    this.state.scoreboardOnline.setCurrentPage(pageNumber);
    this.setState({ scoreboardOnline: this.state.scoreboardOnline });
  }

  navigationBar() {
    let div = [];
    let currentPage = this.state.scoreboardOnline.getCurrentPage() * 1;
    let page1 = currentPage === 1 ? "-" : "+";
    let page2 = currentPage === 2 ? "-" : "+";
    let page3 = currentPage === 3 ? "-" : "+";

    div.push(
      <input
        key="teamLabel"
        type="button"
        id="team"
        className="expandButton"
        value={page1}
        onClick={this.setCurrentPage.bind(this, 1)}
      />
    );
    div.push(
      <label key="team" className="headers" htmlFor="team">
        My Teams
      </label>
    );

    if (currentPage === 1) {
      let myTeams = this.state.scoreboardOnline.getMyTeams();

      for (let i = 0; i < myTeams.length; i++) {
        div.push(<br key={"lb2_" + i + myTeams[i].getTeamName()} />);
        div.push(
          <label
            key={"myTeam" + i}
            className="headers indent"
            onClick={this.setActionPage.bind(this, 1, myTeams[i].getTeamId())}
          >
            {myTeams[i].getTeamName()}
          </label>
        );

        if (this.state.scoreboardOnline.isScorekeeper(myTeams[i].getTeamId())) {
          div.push(<br key={"lb3" + i} />);
          div.push(
            <label
              key={"users" + i}
              className="headers indent2"
              onClick={this.setActionPage.bind(this, 4, myTeams[i].getTeamId())}
            >
              Users
            </label>
          );
        }
      }

      div.push(<br key="lb10" />);
      div.push(
        <input
          key="addTeam"
          type="button"
          className="indent"
          value="Add New Team"
          name="addNewTeam"
          onClick={this.setActionPage.bind(this, 1, -0.1)}
        />
      );
    }

    div.push(<br key="lb4" />);
    div.push(
      <input
        key="lbRegional"
        type="button"
        id="regionalTeams"
        className="expandButton"
        value={page2}
        onClick={this.setCurrentPage.bind(this, 2)}
      />
    );
    div.push(
      <label key="regional" className="headers" htmlFor="regionalTeams">
        Regional Teams
      </label>
    );

    if (currentPage === 2) {
      let guestTeams = this.state.scoreboardOnline.getGuestTeams();

      for (let i = 0; i < guestTeams.length; i++) {
        div.push(<br key={"lb5_" + i} />);
        div.push(
          <label
            key={"guestTeam" + i}
            className="headers indent"
            onClick={this.setActionPage.bind(
              this,
              1,
              guestTeams[i].getTeamId()
            )}
          >
            {guestTeams[i].getTeamName()}
          </label>
        );
      }
      div.push(<br key="lb6" />);
      div.push(
        <input
          key="addRegionalTeam"
          type="button"
          className="indent"
          value="Add New Team"
          name="addNewRegionalTeam"
          onClick={this.setActionPage.bind(this, 1, -0.2)}
        />
      );
    }

    div.push(<br key="lb7" />);
    div.push(
      <input
        key="lbSaved"
        type="button"
        id="savedGames"
        className="expandButton"
        value={page3}
        onClick={this.setCurrentPage.bind(this, 3)}
      />
    );
    div.push(
      <label key="saved" className="headers" htmlFor="savedGames">
        Saved Games/Scores
      </label>
    );

    if (currentPage === 3) {
      div.push(<br key="lb10" />);
      div.push(
        <label
          key="lbAddNewGame"
          className="headers indent"
          onClick={this.setActionPage.bind(this, 6)}
        >
          Unscheduled Games
        </label>
      );
      div.push(<br key="lb8" />);
      div.push(
        <label
          key="lbAddNewGame"
          className="headers indent"
          onClick={this.setActionPage.bind(this, 5)}
        >
          Event Calendar
        </label>
      );
      div.push(<br key="lb9" />);
      div.push(
        <input
          key="addNew"
          type="button"
          className="indent"
          value="Add New"
          name="addNewGame"
          onClick={this.setActionPage.bind(this, 3)}
        />
      );
    }

    return div;
  }

  leftNavigation = () => {
    return React.createElement(
      "div",
      {
        key: "navBar" + new Date().toLocaleTimeString(),
        className: "navigationBar"
      },
      this.navigationBar()
    );
  };

  subscriptionData = () => {
    this.state.scoreboardOnline.apiRepo.subscriptionData(
      this.subscriptionReturnMethod,
      this.subscriptionErrorMethod
    );
  };

  subscriptionReturnMethod = resultsJson => {
    let scoreboardOnline = this.state.scoreboardOnline;

    if (resultsJson.subscriptionEndDate !== null){
      scoreboardOnline.subscriptionStatus = (resultsJson.subscriptionEndDate ==="active");
    }

    if (resultsJson.freeGames !== null) {
      scoreboardOnline.freeGames = resultsJson.freeGames;
    }

    if (resultsJson.gameList !== null && resultsJson.gameList !== undefined) {
      for (let i = 0; i < resultsJson.gameList.length; i++) {
        scoreboardOnline.freeGameList.push(resultsJson.gameList[i]);
      }
    }
  };

  subscriptionErrorMethod = resultsJson => {
    this.setActionPage.bind(this, 8);
  };

  preloadData = () => {
    this.state.scoreboardOnline.apiRepo.preloadData(
      this.preloadReturnMethod,
      this.preloadErrorMethod
    );
  };

  preloadReturnMethod = resultsJson => {
    let scoreboardOnline = this.state.scoreboardOnline;

    if (resultsJson.myTeams !== null && resultsJson.myTeams !== undefined) {
      for (let i = 0; i < resultsJson.myTeams.length; i++) {
        let newTeam = new TeamInformation(resultsJson.myTeams[i]);
        scoreboardOnline.addTeam(newTeam);
      }
    }

    if (resultsJson.myTeams !== null && resultsJson.myTeams !== undefined) {
      for (let i = 0; i < resultsJson.regionalTeams.length; i++) {
        let newTeam = new TeamInformation(resultsJson.regionalTeams[i]);

        scoreboardOnline.addTeam(newTeam);
      }
    }

    if (resultsJson.gameTypes !== null && resultsJson.gameTypes !== undefined) {
      for (let i = 0; i < resultsJson.gameTypes.length; i++) {
        let newGameTypes = new GameType(resultsJson.gameTypes[i]);

        scoreboardOnline.gameTypes.push(newGameTypes);
      }
    }

    if (
      resultsJson.teamPermissions !== null &&
      resultsJson.teamPermissions !== undefined
    ) {
      for (let i = 0; i < resultsJson.teamPermissions.length; i++) {
        let teamPermissions = new TeamPermission(
          resultsJson.teamPermissions[i]
        );

        scoreboardOnline.addTeamPermissions(teamPermissions);
      }
    }

    if (
      resultsJson.savedGames !== null &&
      resultsJson.savedGames !== undefined
    ) {
      for (let i = 0; i < resultsJson.savedGames.length; i++) {
        let teamPermissions = new ScheduledGame(resultsJson.savedGames[i]);

        scoreboardOnline.addSavedGame(teamPermissions);
      }
    }

    this.setState({
      scoreboardOnline: scoreboardOnline,
      preloadComplete: true
    });
  };

  preloadErrorMethod = () => {
    alert(
      "Unable to load your data. Please logout and log into account again."
    );
  };

  getUsers = () => {
    this.state.scoreboardOnline.apiRepo.getUsers(
      this.getUsersReturnMethod,
      this.getUsersErrorMethod
    );
  };

  getUsersErrorMethod = () => {
    alert(
      "Unable to load users. Please check your connectivity and try clicking the Users link again."
    );
  };

  getUsersReturnMethod = resultsJson => {
    let scoreboardOnline = this.state.scoreboardOnline;

    if (resultsJson !== null && resultsJson !== undefined) {
      let myTeams = JSON.parse(resultsJson.myTeams);

      for (let i = 0; i < myTeams.length; i++) {
        let newTeam = new TeamInformation(myTeams[i]);
        scoreboardOnline.addTeam(newTeam);
      }
    }
  };

  refreshMethod = scoreboardOnline => {
    this.setState({ scoreboardOnline: scoreboardOnline });
  };

  render() {
    if (this.state.scoreboardOnline.isLoggedIn) {
      let div = [this.appHeader(), this.leftNavigation()];
      let pageName = "loginPage";

      if (!this.state.preloadComplete) {
        this.preloadData();
        this.subscriptionData();
      }

      if ((this.state.scoreboardOnline.subscriptionStatus !== null) && (!this.state.scoreboardOnline.subscriptionStatus) &&
        (!((this.state.scoreboardOnline.getActionCurrentPage() === 3) || (this.state.scoreboardOnline.getActionCurrentPage() === 5) || (this.state.scoreboardOnline.getActionCurrentPage() === 6)))) {
        pageName = "accountPayments";
      }
      else {
        switch (this.state.scoreboardOnline.getActionCurrentPage()) {
          case 1:
            pageName = "teamPage";
            break;
          case 2:
            pageName = "teamPage";
            break;
          case 3:
            pageName = "savedGamesPage";
            break;
          case 4:
            pageName = "usersPage";
            break;
          case 5:
            pageName = "gameCalendar";
            break;
          case 6:
            pageName = "unscheduledGames";
            break;
          case 7:
            pageName = "updateAccount";
            break;
          case 8:
            pageName = "accountPayments";
            break;
          default:
            pageName = "salesPitch";
            break;
        }
      }

      div.push(this.actionPage(pageName));
      return div;
    } else {
      let div = [this.appHeader()];

      if (this.state.scoreboardOnline.showLogin) {
        div.push(this.actionPage("loginPage", this.refreshMethod));
      } else if (this.state.scoreboardOnline.accountForm) {
        div.push(this.actionPage("newAccount", this.refreshMethod));
      } else {
        div.push(this.actionPage("salesPitch"));
      }
      return div;
    }
  }
}

export default Scoreboard;
render(<Scoreboard />, document.getElementById("root"));
