import React, { Component } from "react";
import UserPage from "./userPageComponent.js";
import TeamPage from "./teamPageComponent.js";
import StaticPages from "./staticPages.js";
import AccountPages from "./accountPages.js";
import GameCalendar from "./gameCalendarComponent.js";

// eslint-disable-next-line
class PageGenerator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scoreboardOnline: props.scoreboardOnline,
      page: props.page,
      callMethod: props.callMethod,
      parentRefreshMethod: props.refreshMethod
    };
  }

  usersPage() {
    return (
      <div key="userPage">
        <UserPage
          teamId={this.state.scoreboardOnline.getCurrentTeamId()}
          apiRepo={this.state.scoreboardOnline.apiRepo}
        />
      </div>
    );
  }

  accountPages(pageName) {
    return (
      <AccountPages
        key="accountPages"
        page={pageName}
        callMethod={this.state.callMethod}
        refreshMethod={this.state.parentRefreshMethod}
        scoreboardOnline={this.state.scoreboardOnline}
      />
    );
  }

  gameCalendar(page) {
    let pageName = page.split("#")[0];
    return (
      <GameCalendar
        key={page}
        scoreboardOnline={this.state.scoreboardOnline}
        page={pageName}
      />
    );
  }

  myTeamPage = () => {
    return (
      <TeamPage
        key="myTeamPage"
        scoreboardOnline={this.state.scoreboardOnline}
        refreshMethod={this.state.parentRefreshMethod}
      />
    );
  };

  // noinspection JSMethodCanBeStatic
  staticPages(pageName) {
    return <StaticPages page={pageName} />;
  }

  render() {
    switch (this.state.page) {
      case "newAccount":
        return this.accountPages("newAccount");
      case "loginPage":
        return this.accountPages("loginPage");
      case "updateAccount":
        return this.accountPages("updateAccount");
      case "accountPayments":
        return this.accountPages("accountPayments");
      case "teamPage":
        return this.myTeamPage();
      case "addTeamPage":
        return this.myTeamPage();
      case "savedGamesPage":
        return this.gameCalendar("savedGamesPage#" + Math.random());
      case "unscheduledGames":
        return this.gameCalendar("unscheduledGames#" + Math.random());
      case "gameCalendar":
        return this.gameCalendar("gameCalendar#" + Math.random());
      case "salesPitch":
        return this.staticPages("salesPitch");
      case "usersPage":
        return this.usersPage();
      default:
        return this.staticPages("salesPitch");
    }
  }
}

export default PageGenerator;
