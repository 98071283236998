import React, { Component } from "react";
import TeamName from "./teamname.js";
import moment from "moment";

// eslint-disable-next-line
class ScheduledGamePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scoreboardOnline: props.scoreboardOnline,
      apiRepo: props.scoreboardOnline.apiRepo,
      parentRefreshMethod: props.refreshMethod,
      gameInfo: props.scheduledGame,
      teamSearches: [],
      includedTeams: props.scheduledGame.teams,
      includeInState: 2
    };
  }

  addTeam = () => {
    let availableTeams = this.availableTeams.selectedOptions;
    let teamsSearched = this.searchResultsElement
      ? this.searchResultsElement.selectedOptions
      : [];
    let includedTeams = this.state.includedTeams;

    for (let i = availableTeams.length - 1; i >= 0; i--) {
      let team = this.state.scoreboardOnline.getTeam(
        availableTeams[i].value * 1
      );
      availableTeams[i].selected = false;

      if (team && !this.isAlreadyIncluded(team.getTeamId())) {
        includedTeams.push(team);
      }
    }

    for (let i = teamsSearched.length - 1; i >= 0; i--) {
      let team = this.getSearchedTeam(teamsSearched[i].value * 1);

      teamsSearched[i].selected = false;

      if (team && !this.isAlreadyIncluded(team.getTeamId())) {
        includedTeams.push(team);

        if (this.state.includeInState !== 2) {
          let existingTeam = this.state.scoreboardOnline.getTeam(
            team.getTeamId()
          );

          if (existingTeam === null) {
            let bodyContent = {
              teamId: team.getTeamId(),
              myTeam: this.state.includeInState === 0
            };

            this.state.scoreboardOnline.apiRepo.followTeam(
              bodyContent,
              null,
              null
            );
          }
        }
      }
    }

    this.setState({
      includedTeams: includedTeams
    });
  };

  isAlreadyIncluded(teamId) {
    for (let i = 0; i < this.state.includedTeams.length; i++) {
      if (this.state.includedTeams[i].getTeamId() === teamId) {
        return true;
      }
    }

    return false;
  }

  getSearchedTeam = teamId => {
    for (let i = 0; i < this.state.teamSearches.length; i++) {
      if (this.state.teamSearches[i].getTeamId() === teamId) {
        return this.state.teamSearches[i];
      }
    }

    return null;
  };

  teamSearch = () => {
    let searchText = this.teamSearchElement.value;

    if (searchText.length === 0) {
      this.setState({
        teamSearches: []
      });
    } else if (searchText.length > 3) {
      this.state.apiRepo.searchTeams(
        searchText,
        this.searchTeamsReturnMethod,
        null
      );
    }
  };

  searchTeamsReturnMethod = resultsJson => {
    if (resultsJson !== null && resultsJson !== undefined) {
      let teamSearches = [];

      for (let i = 0; i < resultsJson.length; i++) {
        let team = new TeamName(resultsJson[i]);

        teamSearches.push(team);
      }

      this.setState({
        teamSearches: teamSearches
      });
    }
  };

  instructions(teamId) {}

  removeTeam = () => {
    let includedTeamsList = this.includedTeams.selectedOptions;
    let includedTeams = this.state.includedTeams;

    for (let i = 0; i < includedTeamsList.length; i++) {
      for (let k = includedTeams.length - 1; k >= 0; k--) {
        if (includedTeams[k].getTeamId() === includedTeamsList[i].value * 1) {
          includedTeams.splice(k, 1);
        }
      }
    }

    this.setState({
      includedTeams: includedTeams
    });
  };

  saveTeamInfo = event => {
    event.preventDefault();

    if (this.state.scoreboardOnline.subscriptionStatus ||
      (this.state.scoreboardOnline.freeGameList.indexOf(this.state.gameId) >= 0)){

      this.doSaveTeamInfo(event, false);
    } else if (this.state.scoreboardOnline.freeGames > 0){
      let message = "Your subscription isn't current. If you want to use one of your " + this.state.scoreboardOnline.freeGames +
        " remaining free games to save your scorecard please select \"Ok\", otherwise click \"Cancel\" to not save your game.";

      if (window.confirm(message)) {
        this.doSaveTeamInfo(event, true);
      }
    } else {
      alert("Your subscription isn't current and you've run out of free games. Please go to the \"Edit/View Payment Info\" " +
        "to get a subscription to this site before trying to add new or save changes to any more games.");
    }
  };

  doSaveTeamInfo(event, useFreeGame){
    this.saveButton.disabled = true;
    //    this.saveClearButton.disabled = true;
    this.cancelButton.disabled = true;

    const formData = new FormData(event.target);
    let includedTeams = this.includedTeams;
    let newGame = this.state.gameInfo;
    let formErrors = false;
    let gameDateTime = null;
    let gameDateTimeEnd = null;

    if (includedTeams.length <= 0) {
      formErrors = true;
    }

    if (formData.get("gameDate") !== "") {
      gameDateTime = new Date(
        formData.get("gameDate") + " " + formData.get("gameTime")
      );

      if (isNaN(gameDateTime.getTime())) {
        formErrors = true;
      }
    }

    if (formData.get("dateEnd") !== "") {
      gameDateTimeEnd = new Date(
        formData.get("dateEnd") + " " + formData.get("timeEnd")
      );

      if (isNaN(gameDateTimeEnd.getTime())) {
        formErrors = true;
      }
    }

    if (formErrors) {
      this.saveButton.disabled = false;
      //      this.saveClearButton.disabled = false;
      this.cancelButton.disabled = false;

      alert(
        "Please check that you have included Teams and have a valid Date, then retry saving this game.\nA date isn't required, but if you put in a date or time, it needs to be valid."
      );
    } else {
      newGame.gameType = formData.get("gameType") * 1;
      newGame.datePlayed = gameDateTime;
      newGame.endTime = gameDateTimeEnd;

      newGame.teams = [];
      newGame.teamIds = [];

      for (let i = 0; i < includedTeams.length; i++) {
        let team = new TeamName();

        newGame.teamIds.push(includedTeams[i].value * 1);

        team.teamId = includedTeams[i].value * 1;
        team.teamName = includedTeams[i].text;

        newGame.teams.push(team);
      }

      this.state.scoreboardOnline.addSavedGame(newGame);

      this.state.apiRepo.saveGame(
        newGame,
        useFreeGame,
        this.saveGameReturnMethod,
        this.saveGameErrorMethod
      );

      this.setState({
        gameInfo: newGame,
        scoreboardOnline: this.state.scoreboardOnline
      });
    }
  };

  saveGameReturnMethod = resultsJson => {
    let gameInfo = this.state.gameInfo;

    if (gameInfo.gameId >= 0) {

      gameInfo = this.state.scoreboardOnline.getSavedGame(
        gameInfo.gameId
      );
    } else {
      gameInfo.gameId = resultsJson[0].gameId;
    }

    if (this.saveButton) {
      this.saveButton.disabled = false;
      //    this.saveClearButton.disabled = false;
      this.cancelButton.disabled = false;
    }

    this.setState({ gameInfo: gameInfo });
  };

  saveGameErrorMethod = () => {
    if (this.saveButton) {
      this.saveButton.disabled = false;
      //    this.saveClearButton.disabled = false;
      this.cancelButton.disabled = false;
    }
    alert("Your game was not saved. Please try again.");
  };

  includeInMyTeams = () => {
    this.setState({
      includeInState: 0
    });
  };

  includeInRegional = () => {
    this.setState({
      includeInState: 1
    });
  };

  includeInJustGame = () => {
    this.setState({
      includeInState: 2
    });
  };

  dateTimeInstructions = () => {
    // explain how the time doesn't matter for a date, but the date matters for a time.
  };

  resetForm = () => {
    this.scheduleGameForm.reset();

    this.setState({
      includedTeams: []
    });
  };

  render() {
    let datePlayed = null;
    let timePlayed = null;
    let endDatePlayed = null;
    let endTimePlayed = null;
    let theTime;

    if (this.state.gameInfo.datePlayed) {
      theTime = moment(this.state.gameInfo.datePlayed);
      datePlayed = theTime.format("YYYY-MM-DD");
      timePlayed = theTime.format("HH:mm:SS.000");
    }

    if (this.state.gameInfo.endTime) {
      theTime = moment(this.state.gameInfo.endTime);
      endDatePlayed = theTime.format("YYYY-MM-DD");
      endTimePlayed = theTime.format("HH:mm:SS.000");
    }

    return (
      <form
        name="scheduleGame"
        onSubmit={this.saveTeamInfo}
        ref={function(node) {
          this.scheduleGameForm = node;
        }.bind(this)}
      >
        <table>
          <tbody>
            <tr>
              <td className="alignTop" rowSpan="3">
                Add Team:
              </td>
              <td className="alignTop" rowSpan="2">
                <select
                  name="addTeam"
                  size="10"
                  defaultValue=""
                  className="listBox"
                  multiple="multiple"
                  ref={function(node) {
                    this.availableTeams = node;
                  }.bind(this)}
                >
                  {this.state.scoreboardOnline.getMyTeams().map(function(item) {
                    return <option value={item.teamId}>{item.teamName}</option>;
                  })}
                  {this.state.scoreboardOnline
                    .getGuestTeams()
                    .map(function(item) {
                      return (
                        <option value={item.teamId}>{item.teamName}</option>
                      );
                    })}
                </select>
              </td>
              <td rowSpan="2" className="alignCenter">
                <hr width="1" size="50" />
                OR
                <br />
                <hr width="1" size="50" />
              </td>
              <td className="alignTop">
                <input
                  type="text"
                  name="userSearch"
                  onKeyUp={this.teamSearch}
                  ref={function(node) {
                    this.teamSearchElement = node;
                  }.bind(this)}
                />
                <input
                  type="button"
                  value="?"
                  title="Instructions"
                  onClick={this.instructions}
                />
              </td>
            </tr>
            <tr>
              <td className="alignTop">
                {this.state.teamSearches.length > 0 && (
                  <div>
                    <select
                      name="searchResults"
                      size="5"
                      className="fullWidth"
                      multiple="multiple"
                      ref={function(node) {
                        this.searchResultsElement = node;
                      }.bind(this)}
                    >
                      {this.state.teamSearches.map(function(item) {
                        return (
                          <option value={item.teamId}>{item.teamName}</option>
                        );
                      })}
                    </select>
                    <br />
                    Also include Team(s) in:
                    <div>
                      <input
                        type="radio"
                        name="includeIn"
                        id="myTeams"
                        value="myTeams"
                        checked={this.state.includeInState === 0}
                        onChange={this.includeInMyTeams}
                      />
                      <label htmlFor="myTeams">My Teams</label>
                      <br />
                      <input
                        type="radio"
                        name="includeIn"
                        id="regional"
                        value="regional"
                        checked={this.state.includeInState === 1}
                        onChange={this.includeInRegional}
                      />
                      <label htmlFor="regional">Regional Teams</label>
                      <br />
                      <input
                        type="radio"
                        name="includeIn"
                        id="justThisGame"
                        value="justThisGame"
                        checked={this.state.includeInState === 2}
                        onChange={this.includeInJustGame}
                      />
                      <label htmlFor="justThisGame">Just for this game</label>
                    </div>
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td colSpan="3" className="alignCenter">
                <input
                  type="button"
                  value="Add Selected Team(s)"
                  onClick={this.addTeam}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="5">
                <hr />
              </td>
            </tr>
            <tr>
              <td>Game Type:</td>
              <td>
                <select
                  name="gameType"
                  defaultValue={this.state.gameInfo.gameType}
                >
                  {this.state.scoreboardOnline.gameTypes.map(function(item) {
                    return <option value={item.id}>{item.gameName}</option>;
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td className="alignTop">
                Included Teams: <label className="required">*</label>
              </td>
              <td>
                <select
                  name="includedTeams"
                  size="5"
                  className="fullWidth"
                  multiple="true"
                  ref={function(node) {
                    this.includedTeams = node;
                  }.bind(this)}
                >
                  {this.state.includedTeams.map(function(item) {
                    return <option value={item.teamId}>{item.teamName}</option>;
                  })}
                </select>
              </td>
              <td>
                <input
                  type="button"
                  value="Remove Team(s)"
                  onClick={this.removeTeam}
                />
              </td>
            </tr>
            <tr>
              <td>Date & Time:</td>
              <td colSpan="2" className="alignLeft">
                <input type="date" name="gameDate" defaultValue={datePlayed} />
                <input type="time" name="gameTime" defaultValue={timePlayed} />
                <input
                  type="button"
                  value="?"
                  title="Instructions"
                  onClick={this.dateTimeInstructions}
                />
              </td>
            </tr>
            <tr>
              <td>Expected End:</td>
              <td colSpan="2" className="alignLeft">
                <input
                  type="date"
                  name="dateEnd"
                  defaultValue={endDatePlayed}
                />
                <input
                  type="time"
                  name="timeEnd"
                  defaultValue={endTimePlayed}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <input
                  type="submit"
                  value="Save"
                  name="Save"
                  ref={function(node) {
                    this.saveButton = node;
                  }.bind(this)}
                />
                <input
                  type="hidden"
                  value="Save & Clear Form"
                  name="SaveClear"
                  ref={function(node) {
                    this.saveClearButton = node;
                  }.bind(this)}
                />
              </td>
              <td colSpan="2">
                <input
                  type="button"
                  value="Cancel"
                  onClick={this.resetForm}
                  ref={function(node) {
                    this.cancelButton = node;
                  }.bind(this)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  }
}

export default ScheduledGamePage;
