// eslint-disable-next-line
class TeamPermission {
  constructor(newTeam) {
    if (newTeam) {
      this.teamId = newTeam.teamId;
      this.scorekeeper = newTeam.scorekeeper;
      this.coach = newTeam.coach;
      this.blocked = newTeam.blocked;
    } else {
      this.teamId = null;
      this.scorekeeper = false;
      this.coach = false;
      this.blocked = false;
    }
  }
}

export default TeamPermission;
