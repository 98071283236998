// eslint-disable-next-line
import TeamInformation from "./teamInformation";
import ScoreboardApiRepository from "./scoreboardApiRepository";

class ScoreboardOnline {
  constructor() {
    this.username = "";
    this.password = "";
    this.userId = "";
    this.firstName = "";
    this.lastName = "";
    this.eMail = "";

    this.scoreKeeper = false;
    this.isLoggedIn = false;
    this.showLogin = false;
    this.newAccount = false;
    //    this.isLoading = false;
    this.error = false;

    this.currentPage = 0;
    this.actionPage = 0;
    this.currentTeamId = 0;

    this.teamInformationList = [];
    this.gameTypes = [];
    this.users = [];
    this.teamPermissions = [];
    this.savedGames = [];
    this.loadedTeams = [{ teamId: -0.1, teamName: "" }];

    this.apiRepo = new ScoreboardApiRepository();

    this.subscriptionStatus = null;
    this.freeGames = 0;
    this.freeGameList = [];
  }

  getCurrentPage = function() {
    return this.currentPage;
  };

  setCurrentPage = function(pageNumber) {
    this.currentPage = pageNumber;
  };

  getActionCurrentPage = function() {
    return this.actionPage;
  };

  setActionCurrentPage = function(pageNumber) {
    this.actionPage = pageNumber;
  };

  isScoreKeeper = function() {
    return this.scoreKeeper;
  };

  setScoreKeeper = function(isScoreKeeper) {
    this.scoreKeeper = isScoreKeeper;
  };

  addTeam = function(teamInfo) {
    if (this.getTeam(Math.abs(teamInfo.getTeamId())) === null) {
      this.teamInformationList.push(teamInfo);

      if (this.teamInformationList.length === 1) {
        this.currentTeamId = teamInfo.teamId;
      }
    }
  };

  getCurrentTeam = () => {
    for (let i = 0; i < this.teamInformationList.length; i++) {
      if (this.teamInformationList[i].getTeamId() === this.currentTeamId) {
        return this.teamInformationList[i];
      }
    }

    return null;
  };

  getCurrentTeamId = function() {
    return this.currentTeamId;
  };

  getTeam = function(teamId) {
    for (let i = 0; i < this.teamInformationList.length; i++) {
      if (this.teamInformationList[i].getTeamId() === teamId) {
        return this.teamInformationList[i];
      }
    }

    return null;
  };

  setCurrentTeam = function(currentTeamId) {
    this.currentTeamId = currentTeamId;
  };

  setNameColor = color => {
    this.getCurrentTeam().setNameColor(color);
  };

  setScoreColor = color => {
    this.getCurrentTeam().setScoreColor(color);
  };

  getNameColor = () => {
    return this.getCurrentTeam().getNameColor();
  };

  getScoreColor = () => {
    return this.getCurrentTeam().getScoreColor();
  };

  getMyTeams = function() {
    let teamList = [];

    for (let i = 0; i < this.teamInformationList.length; i++) {
      if (
        this.teamInformationList[i].isMyTeam() === true &&
        this.teamInformationList[i].getTeamId() >= 0
      ) {
        teamList.push(this.teamInformationList[i]);
      }
    }

    return teamList;
  };

  getGuestTeams = function() {
    let teamList = [];

    for (let i = 0; i < this.teamInformationList.length; i++) {
      if (
        this.teamInformationList[i].isMyTeam() === false &&
        this.teamInformationList[i].getTeamId() >= 0
      ) {
        teamList.push(this.teamInformationList[i]);
      }
    }

    return teamList;
  };

  getTeamCount() {
    return this.teamInformationList.length;
  }

  cloneCurrentTeam() {
    return new TeamInformation(this.getCurrentTeam());
  }

  getAllUsers() {
    return this.users;
  }

  getUsers() {
    let availableUsers = [];

    for (let i = 0; i < this.users.length; i++) {
      if (!this.users[i].scoreKeeper && !this.users[i].blocked) {
        availableUsers.push(this.users[i]);
      }
    }

    return this.valueLabelPairs(availableUsers, "id", "fullName");
  }

  getSelectedUsers() {
    let selectedUsers = [];

    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].scoreKeeper) {
        selectedUsers.push(this.users[i]);
      }
    }

    return this.valueLabelPairs(selectedUsers, "id", "fullName");
  }

  getBlockedUsers() {
    let blockedUsers = [];

    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].blocked) {
        blockedUsers.push(this.users[i]);
      }
    }

    return this.valueLabelPairs(blockedUsers, "id", "fullName");
  }

  // noinspection JSMethodCanBeStatic
  valueLabelPairs(list, value, label) {
    let valueLabels = [];

    for (let i = 0; i < list.length; i++) {
      let item = {};
      item["value"] = list[i][value];
      item["label"] = list[i][label];

      valueLabels.push(item);
    }

    return valueLabels;
  }

  getExistingTeamNames() {
    return this.loadedTeams;
  }

  addLoadedTeam(newTeam) {
    if (this.getTeam(newTeam.teamId) === null) {
      let loaded = false;

      for (let i = 0; i < this.loadedTeams.length && !loaded; i++) {
        if (this.loadedTeams[i].teamId === newTeam.teamId) {
          this.loadedTeams[i] = newTeam;
          loaded = true;
        }
      }

      if (!loaded) {
        this.loadedTeams.push(newTeam);
      }
    }
  }

  containsTeamId(teamId) {
    for (let i = 0; i < this.loadedTeams.length; i++) {
      if (this.loadedTeams[i].teamId === teamId) {
        return true;
      }
    }

    return false;
  }

  getPermissions(teamId) {
    for (let i = 0; i < this.teamPermissions.length; i++) {
      if (this.teamPermissions[i].teamId === teamId) {
        return this.teamPermissions[i];
      }
    }
    return null;
  }

  isScorekeeper(teamId) {
    if (teamId === -0.1) {
      return true;
    }

    let teamPermission = this.getPermissions(teamId);

    if (teamPermission) {
      return (
        !teamPermission.blocked &&
        (teamPermission.coach || teamPermission.scorekeeper)
      );
    }
    return false;
  }

  isCoach(teamId) {
    let teamPermission = this.getPermissions(teamId);

    if (teamPermission) {
      return !teamPermission.blocked && teamPermission.coach;
    }
    return false;
  }

  getGameName(gameType) {
    for (let i = 0; i < this.gameTypes.length; i++) {
      if (this.gameTypes[i].id === gameType) {
        return this.gameTypes[i].gameName;
      }
    }
  }

  addTeamPermissions(permission) {
    if (permission) {
      let teamPermission = this.getPermissions(permission.teamId);

      if (teamPermission === null) {
        this.teamPermissions.push(permission);
      }
    }
  }

  addSavedGame(game) {
    if (game) {
      let savedGame = this.getSavedGame(game.gameId);

      if (savedGame === null) {
        this.savedGames.push(game);
      } else {
        savedGame.update(game);
      }
    }
  }

  getAllEvents() {
    let calendarEvents = [];
    for (let i = 0; i < this.savedGames.length; i++) {
      if (this.savedGames[i].datePlayed) {
        let event = this.savedGames[i].getEvent();

        event.title =
          this.getGameName(this.savedGames[i].gameType) + " - " + event.title;
        calendarEvents.push(event);
      }
    }

    return calendarEvents;
  }

  getSavedGame(gameId) {
    for (let i = 0; i < this.savedGames.length; i++) {
      if (this.savedGames[i].gameId === gameId) {
        return this.savedGames[i];
      }
    }

    return null;
  }

  getUnscheduledGames() {
    let unscheduledGames = [];

    for (let i = 0; i < this.savedGames.length; i++) {

      if (
        this.savedGames[i].datePlayed === null ||
        this.savedGames[i].datePlayed === "" ||
        this.savedGames[i].datePlayed === "0000-00-00 00:00:00"
      ) {
        unscheduledGames.push(this.savedGames[i]);
      }
    }

    return unscheduledGames;
  }
}

export default ScoreboardOnline;
