import TeamName from "./teamname.js";

// eslint-disable-next-line
class ScheduledGame {
  constructor(newGame) {
    if (newGame) {
      this.update(newGame);
    } else {
      this.gameId = -1;
      this.gameType = 0;
      this.scorekeepers = [];
      this.teams = [];
      this.teamIds = [];
      this.datePlayed = "";
      this.endTime = "";
    }
  }

  update(newGame) {
    this.gameId = newGame.gameId;
    this.gameType = newGame.gameType;
    this.scorekeepers = newGame.scorekeepers;
    this.teamIds = newGame.teamIds;
    this.datePlayed = new Date(newGame.datePlayed);
    this.endTime = new Date(newGame.endTime);

    if ((!newGame.datePlayed) || (isNaN(this.datePlayed)) || (newGame.datePlayed === "0000-00-00 00:00:00")){
      this.datePlayed = null;
    }

    if ((!newGame.endTime) || (isNaN(this.endTime)) || (newGame.endTime === "0000-00-00 00:00:00")) {
      this.endTime = null;
    }

    this.teams = [];
    for (let i = 0; i < newGame.teams.length; i++) {
      this.teams.push(new TeamName(newGame.teams[i]));
    }
  }

  getEvent() {
    return {
      id: this.gameId,
      start: this.datePlayed,
      end:
        this.endTime ||
        new Date(
          this.datePlayed.getUTCFullYear(),
          this.datePlayed.getUTCMonth(),
          this.datePlayed.getUTCDate(),
          this.datePlayed.getUTCHours() + 1,
          this.datePlayed.getUTCMinutes(),
          this.datePlayed.getUTCSeconds()
        ),
      title:
        (this.teams.length === 0 ? "" : (this.teams[0].teamName +
        (this.teams.length > 1 ? " " + this.teams[1].teamName : "")))
    };
  }
}

export default ScheduledGame;
