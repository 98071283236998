// eslint-disable-next-line
class GameType {
  constructor(data) {
    if (data === null || data === undefined) {
      this.id = [];
      this.gameName = [];
      this.gameDescription = [];
      this.gameView = [];
    } else {
      this.id = data.id;
      this.gameName = data.gameName;
      this.gameDescription = data.gameDescription;
      this.gameView = data.gameView;
    }
  }
}

export default GameType;
