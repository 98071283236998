import React, { Component } from "react";
import Users from "./usersmodel";

class UserPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      teamId: props.teamId,
      allUsers: [],
      userSearches: [],
      currentFollowers: [],
      scorekeepers: [],
      coaches: [],
      blockedUsers: [],
      apiRepo: props.apiRepo
    };
  }

  componentDidMount() {
    this.getTeamUsers(this.state.teamId);
  }

  findUsers(
    userIds,
    list // userIds: list of numeric user ids // list: list to search
  ) // return a list of users that were found
  {
    if (userIds && list && userIds.length > 0 && list.length > 0) {
      let userList = [];
      let userIndex;

      for (let i = 0; i < userIds.length; i++) {
        userIndex = this.findSingleUserIndex(userIds[i], list);

        if (userIndex !== null && userIndex !== undefined) {
          userList.push(list[userIndex]);
        }
      }
      return userList;
    }
  }

  // noinspection JSMethodCanBeStatic
  findSingleUserIndex(userId, list) {
    for (let i = 0; i < list.length; i++) {
      if (userId === list[i].userId) {
        return i;
      }
    }
  }

  removeUser(users, list) {
    if (users && list && list.length > 0) {
      let userIndex;
      for (let i = 0; i < users.length; i++) {
        userIndex = this.findSingleUserIndex(users[i].userId, list);

        if (userIndex !== null && userIndex !== undefined) {
          list.splice(userIndex, 1);
        }
      }
    }
  }

  includeUser(users, list) {
    if (users) {
      for (let i = 0; i < users.length; i++) {
        if (!this.findSingleUserIndex(users[i].userId, list)) {
          list.push(users[i]);
        }
      }
    }
  }

  swapUsers(removeList, addList, options) {
    let userIds = [];

    for (let i = 0; i < options.length; i++) {
      userIds.push(options[i].value * 1);
    }

    let users = this.findUsers(userIds, removeList);

    this.removeUser(users, removeList);
    this.includeUser(users, addList);

    return userIds;
  }

  removeScorekeeper = () => {
    let selectedOptions = this.scorekeepersElement.selectedOptions;

    let userIds = this.swapUsers(
      this.state.scorekeepers,
      this.state.currentFollowers,
      selectedOptions
    );

    this.state.apiRepo.updateTeamUsers(
      this.state.teamId,
      userIds,
      "removeScorekeeper",
      null,
      null
    );

    this.setState({
      scorekeepers: this.state.scorekeepers,
      currentFollowers: this.state.currentFollowers
    });
  };

  addScorekeeper = () => {
    let selectedOptions = this.allFollowersElement.selectedOptions;

    let userIds = this.swapUsers(
      this.state.currentFollowers,
      this.state.scorekeepers,
      selectedOptions
    );

    this.state.apiRepo.updateTeamUsers(
      this.state.teamId,
      userIds,
      "addScorekeeper",
      null,
      null
    );

    this.setState({
      scorekeepers: this.state.scorekeepers,
      currentFollowers: this.state.currentFollowers
    });
  };

  removeAllScorekeeper = () => {
    let selectedOptions = this.scorekeepersElement.options;

    let userIds = this.swapUsers(
      this.state.scorekeepers,
      this.state.currentFollowers,
      selectedOptions
    );

    this.state.apiRepo.updateTeamUsers(
      this.state.teamId,
      userIds,
      "removeScorekeeper",
      null,
      null
    );

    this.setState({
      scorekeepers: this.state.scorekeepers,
      currentFollowers: this.state.currentFollowers
    });
  };

  addAllScorekeeper = () => {
    let selectedOptions = this.allFollowersElement.options;

    let userIds = this.swapUsers(
      this.state.currentFollowers,
      this.state.scorekeepers,
      selectedOptions
    );

    this.state.apiRepo.updateTeamUsers(
      this.state.teamId,
      userIds,
      "addScorekeeper",
      null,
      null
    );

    this.setState({
      scorekeepers: this.state.scorekeepers,
      currentFollowers: this.state.currentFollowers
    });
  };

  blockUser = () => {
    let selectedOptions = this.allFollowersElement.selectedOptions;

    let userIds = this.swapUsers(
      this.state.currentFollowers,
      this.state.blockedUsers,
      selectedOptions
    );

    this.state.apiRepo.updateTeamUsers(
      this.state.teamId,
      userIds,
      "block",
      null,
      null
    );

    this.setState({
      blockedUsers: this.state.blockedUsers,
      currentFollowers: this.state.currentFollowers
    });
  };

  removeBlock = () => {
    let selectedOptions = this.blockedElement.selectedOptions;

    let userIds = this.swapUsers(
      this.state.blockedUsers,
      this.state.currentFollowers,
      selectedOptions
    );

    this.state.apiRepo.updateTeamUsers(
      this.state.teamId,
      userIds,
      "removeBlock",
      null,
      null
    );

    this.setState({
      blockedUsers: this.state.blockedUsers,
      currentFollowers: this.state.currentFollowers
    });
  };

  removeCoach = () => {
    let selectedOptions = this.coachesElement.selectedOptions;

    let userIds = this.swapUsers(
      this.state.coaches,
      this.state.scorekeepers,
      selectedOptions
    );

    this.state.apiRepo.updateTeamUsers(
      this.state.teamId,
      userIds,
      "removeCoach",
      null,
      null
    );

    this.setState({
      scorekeepers: this.state.scorekeepers,
      coaches: this.state.coaches
    });
  };

  addCoach = () => {
    let selectedOptions = this.scorekeepersElement.selectedOptions;

    let userIds = this.swapUsers(
      this.state.scorekeepers,
      this.state.coaches,
      selectedOptions
    );

    this.state.apiRepo.updateTeamUsers(
      this.state.teamId,
      userIds,
      "addCoach",
      null,
      null
    );

    this.setState({
      scorekeepers: this.state.scorekeepers,
      coaches: this.state.coaches
    });
  };

  userSearch = () => {
    let searchText = this.userSearchElement.value;

    if (searchText.length === 0) {
      this.setState({
        userSearches: []
      });
    } else if (searchText.length > 3) {
      this.state.apiRepo.searchUsers(
        searchText,
        this.searchUsersReturnMethod,
        null
      );
    }
  };

  searchUsersReturnMethod = resultsJson => {
    if (resultsJson !== null && resultsJson !== undefined) {
      let userSearches = [];

      for (let i = 0; i < resultsJson.length; i++) {
        let user = new Users(resultsJson[i]);

        userSearches.push(user);
      }

      this.setState({
        userSearches: userSearches
      });
    }
  };

  addFollower = () => {
    let options = this.searchResultsElement.selectedOptions;
    let userIds = [];

    for (let i = 0; i < options.length; i++) {
      userIds.push(options[i].value * 1);
    }

    let users = this.findUsers(userIds, this.state.userSearches);

    for (let i = 0; i < users.length; i++) {
      if (!this.findSingleUserIndex(users[i].userId, this.state.allUsers)) {
        this.state.currentFollowers.push(users[i]);
        this.state.allUsers.push(users[i]);
      }
    }

    this.state.apiRepo.updateTeamUsers(
      this.state.teamId,
      userIds,
      "add",
      null,
      null
    );

    this.setState({
      currentFollowers: this.state.currentFollowers
    });
  };

  removeFollower = () => {
    let options = this.allFollowersElement.selectedOptions;
    let userIds = [];

    for (let i = 0; i < options.length; i++) {
      userIds.push(options[i].value * 1);
    }

    let users = this.findUsers(userIds, this.state.currentFollowers);

    this.removeUser(users, this.state.currentFollowers);
    this.removeUser(users, this.state.allUsers);

    this.state.apiRepo.updateTeamUsers(
      this.state.teamId,
      userIds,
      "remove",
      null,
      null
    );

    this.setState({
      currentFollowers: this.state.currentFollowers,
      allUsers: this.state.allUsers
    });
  };

  instructions(teamId) {}

  getTeamUsers(teamId) {
    this.state.apiRepo.getTeamUsers(
      teamId,
      this.teamUsersReturnMethod,
      this.teamUsersErrorMethod
    );
  }

  teamUsersErrorMethod = () => {
    alert(
      "Unable to get team users. Please retry if this is the first time you've seen this error."
    );
  };

  teamUsersReturnMethod = resultsJson => {
    let currentFollowers = [];
    let scorekeepers = [];
    let coaches = [];
    let blockedUsers = [];
    let allUsers = [];

    if (resultsJson !== null && resultsJson !== undefined) {
      for (let i = 0; i < resultsJson.length; i++) {
        let user = new Users(resultsJson[i]);

        allUsers.push(user);
        if (user.blocked) {
          blockedUsers.push(user);
        } else if (user.coach) {
          coaches.push(user);
        } else if (user.scorekeeper) {
          scorekeepers.push(user);
        } else {
          // just a follower
          currentFollowers.push(user);
        }
      }
    }

    this.setState({
      allUsers: allUsers,
      currentFollowers: currentFollowers,
      scorekeepers: scorekeepers,
      coaches: coaches,
      blockedUsers: blockedUsers
    });
  };

  render() {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td className="smallCell">Search:</td>
              <td colSpan="2">
                <input
                  type="text"
                  name="userSearch"
                  onKeyUp={this.userSearch}
                  ref={function(node) {
                    this.userSearchElement = node;
                  }.bind(this)}
                />
                <input
                  type="button"
                  value="Add User"
                  onClick={this.addFollower}
                />
              </td>
              <td>
                <input
                  type="button"
                  value="?"
                  title="Instructions"
                  className="floatRight"
                  onClick={this.instructions}
                />
              </td>
            </tr>
            {this.state.userSearches.length > 0 && (
              <tr>
                <td />
                <td colSpan="2">
                  <select
                    name="searchResults"
                    size="5"
                    className="searchResults"
                    multiple="multiple"
                    ref={function(node) {
                      this.searchResultsElement = node;
                    }.bind(this)}
                  >
                    {this.state.userSearches.map(function(item) {
                      return (
                        <option value={item.userId}>{item.fullName}</option>
                      );
                    })}
                  </select>
                </td>
              </tr>
            )}
            <tr>
              <td colSpan="3" className="listBoxHeader">
                Current Followers
              </td>
              <td colSpan="2" className="listBoxHeader">
                Scorekeepers
              </td>
              <td className="listBoxHeader">Coaches</td>
            </tr>
            <tr>
              <td colSpan="2">
                <select
                  name="allFollowers"
                  size="10"
                  defaultValue=""
                  className="listBox"
                  multiple="multiple"
                  ref={function(node) {
                    this.allFollowersElement = node;
                  }.bind(this)}
                >
                  {this.state.currentFollowers.map(function(item) {
                    return <option value={item.userId}>{item.fullName}</option>;
                  })}
                </select>
              </td>
              <td className="smallCell">
                <table>
                  <tbody>
                    <tr>
                      <td className="floatRight">
                        <input
                          type="button"
                          value="&#10005;"
                          title="Remove Follower"
                          onClick={this.removeFollower}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="floatRight">
                        <input
                          type="button"
                          value="&lsaquo;"
                          title="Remove Scorekeeper"
                          onClick={this.removeScorekeeper}
                        />
                      </td>
                      <td>
                        <input
                          type="button"
                          value="&rsaquo;"
                          title="Add As Scorekeeper"
                          onClick={this.addScorekeeper}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="floatRight">
                        <input
                          type="button"
                          value="&laquo;"
                          title="Remove All Scorekeepers"
                          onClick={this.removeAllScorekeeper}
                        />
                      </td>
                      <td>
                        <input
                          type="button"
                          value="&raquo;"
                          title="Add All As Scorekeepers"
                          onClick={this.addAllScorekeeper}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="floatRight">
                        <input
                          type="button"
                          value="&darr;"
                          title="Block Users"
                          onClick={this.blockUser}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <select
                  name="scorekeepers"
                  size="10"
                  defaultValue=""
                  className="listBox"
                  multiple="multiple"
                  ref={function(node) {
                    this.scorekeepersElement = node;
                  }.bind(this)}
                >
                  {this.state.scorekeepers.map(function(item) {
                    return <option value={item.userId}>{item.fullName}</option>;
                  })}
                </select>
              </td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="button"
                          value="&lsaquo;"
                          title="Remove Coach"
                          onClick={this.removeCoach}
                        />
                        <input
                          type="button"
                          value="&rsaquo;"
                          title="Add Coach"
                          onClick={this.addCoach}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <select
                  name="coaches"
                  size="10"
                  defaultValue=""
                  className="listBox"
                  multiple="multiple"
                  ref={function(node) {
                    this.coachesElement = node;
                  }.bind(this)}
                >
                  {this.state.coaches.map(function(item) {
                    return <option value={item.userId}>{item.fullName}</option>;
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td colSpan="2" className="listBoxHeader">
                Blocked
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <select
                  name="blockedUsers"
                  size="5"
                  defaultValue=""
                  className="listBox"
                  multiple="multiple"
                  ref={function(node) {
                    this.blockedElement = node;
                  }.bind(this)}
                >
                  {this.state.blockedUsers.map(function(item) {
                    return <option value={item.userId}>{item.fullName}</option>;
                  })}
                </select>
              </td>
              <td>
                <input
                  type="button"
                  value="&uarr;"
                  title="Remove Block From User"
                  onClick={this.removeBlock}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default UserPage;
