import Users from "./usersmodel";

// eslint-disable-next-line
class TeamInformation {
  constructor(newTeam) {
    if (newTeam) {
      this.updateTeam(newTeam);
    } else {
      this.myTeam = null;
      this.teamId = -0.1;
      this.teamName = "HOME";
      this.nameColor = "#ff0000";
      this.scoreColor = "#ff0000";
      this.gameType = 0;
      this.coaches = [];
      this.schoolName = "";
      this.teamAddress = "";
      this.teamCity = "";
      this.teamState = "";
      this.teamZipCode = "";
      this.phoneNumber = "";
      this.alternatePhoneNumber = "";
      this.teamEmail = "";
      this.teamSite = "";
    }
  }

  updateTeam(newTeamInfo) {
    this.myTeam = newTeamInfo.myTeam;
    this.teamId = newTeamInfo.teamId;
    this.teamName = newTeamInfo.teamName;
    this.nameColor = newTeamInfo.nameColor;
    this.scoreColor = newTeamInfo.scoreColor;
    this.gameType = newTeamInfo.gameType;
    this.schoolName = newTeamInfo.schoolName;
    this.teamAddress = newTeamInfo.teamAddress;
    this.teamCity = newTeamInfo.teamCity;
    this.teamState = newTeamInfo.teamState;
    this.teamZipCode = newTeamInfo.teamZipCode;
    this.phoneNumber = newTeamInfo.phoneNumber;
    this.alternatePhoneNumber = newTeamInfo.alternatePhoneNumber;
    this.teamEmail = newTeamInfo.teamEmail;
    this.teamSite = newTeamInfo.teamSite;

    this.coaches = [];

    if (newTeamInfo.coaches) {
      for (let i = 0; i < newTeamInfo.coaches.length; i++) {
        this.coaches.push(new Users(newTeamInfo.coaches[i]));
      }
    }
  }

  setMyTeam = function(myTeam) {
    this.myTeam = myTeam;
  };

  isMyTeam = function() {
    return this.myTeam;
  };

  setTeamId = function(teamId) {
    this.teamId = teamId;
  };

  getTeamId = function() {
    return this.teamId;
  };

  getTeamName = function() {
    return this.teamName;
  };

  setNameColor = function(color) {
    this.nameColor = color;
  };

  getNameColor = function() {
    return this.nameColor;
  };

  setScoreColor = function(color) {
    this.scoreColor = color;
  };

  getScoreColor = function() {
    return this.scoreColor;
  };

  getGameType = function() {
    return this.gameType;
  };

  getSchoolName = function() {
    return this.schoolName;
  };

  getTeamAddress = function() {
    return this.teamAddress;
  };

  getTeamCity = function() {
    return this.teamCity;
  };

  getTeamState = function() {
    return this.teamState;
  };

  getTeamZipCode = function() {
    return this.teamZipCode;
  };

  getPhoneNumber = function() {
    return this.phoneNumber;
  };

  getAlternatePhoneNumber = function() {
    return this.alternatePhoneNumber;
  };

  getTeamEmail = function() {
    return this.teamEmail;
  };

  getTeamSite = function() {
    return this.teamSite;
  };
}

export default TeamInformation;
