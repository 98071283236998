// eslint-disable-next-line
class Users {
  constructor(data) {
    if (data) {
      this.userId = data.userId;
      this.fullName = data.fullName;
      this.scorekeeper = data.scorekeeper;
      this.blocked = data.blocked;
      this.coach = data.coach;
    } else {
      this.userId = -1;
      this.fullName = "";
      this.scorekeeper = false;
      this.blocked = false;
      this.coach = false;
    }
  }
}

export default Users;
