import sha256 from "crypto-js";

// eslint-disable-next-line
class ScoreboardApiRepository {
  // This class assumes that you are trying to get data from the
  // Scoreboard online API, as well as having the appropriate
  // container for any return data.

  constructor() {
    this.apiUrl = "api/scoreboard.php";
    this.accountUrl = "api/account.php";
    this.stripeUrl = "api/stripepayments.php";
  }

  getGameList(returnMethod, errorMethod) {
    let bodyContent = { getGameList: "true" };

    this.doFetch(this.apiUrl, "POST", bodyContent, returnMethod, errorMethod);
  }

  addGameType(returnMethod, errorMethod) {
    let bodyContent = { addGameType: "true" };

    this.doFetch(this.apiUrl, "POST", bodyContent, returnMethod, errorMethod);
  }

  getSubscriptionInfo(returnMethod, errorMethod) {
    let bodyContent = { getSubscriptionInfo: "true" };

    this.doFetch(
      this.stripeUrl,
      "POST",
      bodyContent,
      returnMethod,
      errorMethod
    );
  }

  cancelSubscription(subscriptionId, returnMethod, errorMethod) {
    let bodyContent = {
      cancelSubscription: "true",
      subscriptionId: subscriptionId
    };

    this.doFetch(
      this.stripeUrl,
      "POST",
      bodyContent,
      returnMethod,
      errorMethod
    );
  }

  getTeamNames(returnMethod, errorMethod) {
    let bodyContent = { getTeamNames: "true" };

    this.doFetch(this.apiUrl, "POST", bodyContent, returnMethod, errorMethod);
  }

  followTeam(bodyContent, returnMethod, errorMethod) {
    bodyContent["followTeam"] = true;

    this.doFetch(this.apiUrl, "POST", bodyContent, returnMethod, errorMethod);
  }

  getTeam(teamId, returnMethod, errorMethod) {
    let bodyContent = { getTeam: "true", teamId: teamId };

    this.doFetch(this.apiUrl, "POST", bodyContent, returnMethod, errorMethod);
  }

  saveGame(bodyContent, useFreeGame, returnMethod, errorMethod) {
    if (bodyContent["gameId"] < 0) {
      bodyContent["saveGame"] = true;
    } else {
      bodyContent["updateGame"] = true;
    }

    if (useFreeGame){
      bodyContent["useFreeGame"] = true;
    }

    this.doFetch(this.apiUrl, "POST", bodyContent, returnMethod, errorMethod);
  }

  saveTeamInfo(formData, elementNames, returnMethod, errorMethod) {
    let bodyContent = ScoreboardApiRepository.formParser(
      formData,
      elementNames
    );

    if (bodyContent["teamId"] < 0) {
      bodyContent["addTeam"] = true;
    } else {
      bodyContent["saveTeam"] = true;
    }

    this.doFetch(this.apiUrl, "POST", bodyContent, returnMethod, errorMethod);
  }

  updateTeamUsers(teamId, userIds, method, returnMethod, errorMethod) {
    let bodyContent = {
      updateTeamUsers: true,
      teamId: teamId,
      userIds: userIds,
      method: method
    };

    this.doFetch(this.apiUrl, "POST", bodyContent, returnMethod, errorMethod);
  }

  getTeamUsers(teamId, returnMethod, errorMethod) {
    let bodyContent = { getTeamUsers: true, teamId: teamId };

    this.doFetch(this.apiUrl, "POST", bodyContent, returnMethod, errorMethod);
  }

  searchTeams(searchString, returnMethod, errorMethod) {
    let bodyContent = { searchTeams: true, searchString: searchString };

    this.doFetch(this.apiUrl, "POST", bodyContent, returnMethod, errorMethod);
  }

  searchUsers(searchString, returnMethod, errorMethod) {
    let bodyContent = { searchUsers: true, searchString: searchString };

    this.doFetch(this.apiUrl, "POST", bodyContent, returnMethod, errorMethod);
  }

  preloadData(returnMethod, errorMethod) {
    let bodyContent = { preload: true };

    this.doFetch(this.apiUrl, "POST", bodyContent, returnMethod, errorMethod);
  }

  subscriptionData(returnMethod, errorMethod) {
    let bodyContent = { subscriptionEndDate: true };

    this.doFetch(this.stripeUrl, "POST", bodyContent, returnMethod, errorMethod);
  }

  login(formData, elementNames, returnMethod, errorMethod) {
    let bodyContent = ScoreboardApiRepository.formParser(
      formData,
      elementNames
    );

    bodyContent["login"] = true;

    this.doFetch(
      this.accountUrl,
      "POST",
      bodyContent,
      returnMethod,
      errorMethod
    );
  }

  addUser(formData, elementNames, returnMethod, errorMethod) {
    let bodyContent = ScoreboardApiRepository.formParser(
      formData,
      elementNames
    );

    bodyContent["addUser"] = true;

    this.doFetch(
      this.accountUrl,
      "POST",
      bodyContent,
      returnMethod,
      errorMethod
    );
  }

  updateUser(userId, formData, elementNames, returnMethod, errorMethod) {
    let bodyContent = ScoreboardApiRepository.formParser(
      formData,
      elementNames
    );

    bodyContent["userId"] = userId;
    bodyContent["updateUser"] = true;

    this.doFetch(this.apiUrl, "POST", bodyContent, returnMethod, errorMethod);
  }

  searchUsername(searchText, returnMethod, errorMethod) {
    let bodyContent = { searchUsername: "true", searchText: searchText };

    this.doFetch(
      this.accountUrl,
      "POST",
      bodyContent,
      returnMethod,
      errorMethod
    );
  }

  searchEmail(searchText, returnMethod, errorMethod) {
    let bodyContent = { searchEmail: "true", searchText: searchText };

    this.doFetch(
      this.accountUrl,
      "POST",
      bodyContent,
      returnMethod,
      errorMethod
    );
  }

  static formParser(formData, elementNames) {
    let bodyObject = {};

    for (let i = 0; i < elementNames.length; i++) {
      if (
        elementNames[i].toLowerCase() === "password" &&
        formData.get("password") !== ""
      ) {
        let saltedHash = sha256.SHA256(
          formData.get("password") + formData.get("username").toLowerCase().trim()
        );
        bodyObject[elementNames[i]] = saltedHash.toString();
      } else {
        bodyObject[elementNames[i]] = formData.get(elementNames[i]);
      }
    }

    return bodyObject;
  }

  doFetch(url, requestType, bodyObject, returnMethod, errorMethod) {
    fetch(url, {
      method: requestType,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(bodyObject)
    })
      .then(results => {
        if (results.ok) {
          return results.json();
        } else {
          if (errorMethod) {
            errorMethod();
          }
        }
      })
      .catch(err => {
        // prevent errors from being displayed.
        // add error handling if necessary.
        //console.log("err", err);
      })
      .then(data => {
        //        console.log("data", data);
        //        console.log("returnMethod", returnMethod);
        if ((data !== null) && (data !== undefined) && returnMethod) {
          returnMethod(data);
        }
      });
  }
}

export default ScoreboardApiRepository;
